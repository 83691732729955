<by-spin [nzSpinning]="addonsLoading$ | async">
  <div nz-row [nzGutter]="0">
    <div nz-col [nzXs]="14" [nzSm]="14">
      <div class="addons-list">
        <nz-select
          nzAllowClear
          class="by-mb-2 by-w-100"
          [(ngModel)]="selectedCategoryId"
          [nzPlaceHolder]="'category' | translate | capitalize"
        >
          <ng-container *ngFor="let categoryId of categories || {} | keys">
            <nz-option
              [nzLabel]="categories[categoryId]"
              [nzValue]="categoryId"
            ></nz-option>
          </ng-container>
        </nz-select>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input
            type="text"
            nz-input
            [(ngModel)]="searchValue"
            [placeholder]="'search' | translate | capitalize"
          />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <i class="fal fa-search"></i>
        </ng-template>

        <nz-collapse
          nzAccordion
          style="border: 0"
          [nzBordered]="false"
          class="by-mb-2"
        >
          <ng-container
            *ngFor="let addonId of addonsForms || {} | keys; let i = index"
          >
            <ng-container *ngIf="addonsForms | get: [addonId, 'form']">
              <nz-collapse-panel
                #collapse
                *ngIf="
                  (addonsForms[addonId].form.get('name').value
                    | filter: searchValue) &&
                  (!selectedCategoryId ||
                    +selectedCategoryId ===
                      +addonsForms[addonId].form.get('category_id').value)
                "
                [nzActive]="addonsForms[addonId].expand"
                [nzHeader]="header"
                [nzShowArrow]="false"
                (nzActiveChange)="onChangeAddonExpandStatus($event, addonId)"
                style="background-color: #ffff"
              >
                <by-addon-estimate-form
                  *ngIf="addonsForms[addonId].expand"
                  [addonForm]="addonsForms[addonId].form"
                  [currencySymbol]="currencySymbol"
                  [accommodationData]="accommodationData"
                  [childrenRange]="childrenRanges"
                  [estimateData]="estimateData$ | async"
                  [vatQuotes]="vatQuotes"
                  [priceLoading]="priceLoading$ | async"
                  (estimate)="estimate($event)"
                  (addToCart)="addToCart(addonId)"
                ></by-addon-estimate-form>
              </nz-collapse-panel>
              <ng-template #header>
                <div
                  nz-row
                  nzJustify="space-between"
                  nzAlign="middle"
                  style="background-color: #ffff"
                  class="by-w-100"
                >
                  <div nz-col>
                    <span>{{
                      addonsForms[addonId].form.get('name').value | capitalize
                    }}</span>
                    <br />
                    <span style="color: #1790f9">
                      {{
                        addonsForms[addonId].form.get('category_title').value
                          | capitalize
                      }}
                      - ({{
                        addonsForms[addonId].form.get('type').value
                          | translate
                          | upperfirst
                      }})
                    </span>
                  </div>
                  <div nz-col style="display: flex; align-items: center">
                    <ng-container
                      *ngIf="
                        showQuantityAndRateConditionMapConstant[
                          addonsForms[addonId].form.get('type').value
                        ]
                      "
                      ><strong
                        ><by-currency-format
                          [currencyValue]="
                            addonsForms[addonId].form.get('price').value
                          "
                        ></by-currency-format
                      ></strong>
                    </ng-container>
                    <i
                      class="fal fa-pencil-alt by-ml-10"
                      style="font-size: 16px; color: #969696"
                    ></i>
                    <ng-container
                      *ngIf="
                        showQuantityAndRateConditionMapConstant[
                          addonsForms[addonId].form.get('type').value
                        ]
                      "
                    >
                      <button
                        class="by-ml-5 ant-btn-icon-only"
                        nz-button
                        type="button"
                        nzType="primary"
                        nzSize="small"
                        nzShape="circle"
                        (click)="$event.stopPropagation(); addToCart(addonId)"
                      >
                        <i class="far fa-plus"></i>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </nz-collapse>
      </div>
    </div>
    <div nz-col [nzXs]="10" [nzSm]="10">
      <div class="cart-box">
        <by-addon-estimate-modal-cart
          [cart]="cart"
          [currencySymbol]="currencySymbol"
          (deleteCartItem)="removeCartItem($event)"
        ></by-addon-estimate-modal-cart>
      </div>
    </div>
  </div>
</by-spin>
