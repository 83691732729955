@if (minAdvanceInvoiceAmountToSet) {
  <nz-alert
    nzType="info"
    [nzMessage]="advanceInvoiceRefundTitle"
    nzShowIcon
    class="by-pb-5"
  ></nz-alert>

  <ng-template #advanceInvoiceRefundTitle>
    {{
      'info_credit_note_with_advance_amount_more_invoice'
        | translate
          : {
              amount: '€' + minAdvanceInvoiceAmountToSet
            }
        | upperfirst
    }}
  </ng-template>
}

<nz-table [nzData]="refundPayments.controls" [nzShowPagination]="false">
  <thead>
    <tr>
      <th class="color--azure by-w-55">
        {{ 'payment' | translate | upperfirst }}
      </th>
      <th class="color--azure">
        {{ 'amount' | translate | upperfirst }}
      </th>
      <th>
        <i
          class="pointer far fa-undo color--orange by-fs-12"
          nz-tooltip
          [nzTooltipTitle]="'reset_default_refunds' | translate | upperfirst"
          (click)="resetRefundsByAmount.emit(maxAmountToCancel)"
        ></i>
      </th>
    </tr>
  </thead>
  <tbody>
    @for (payment of refundPayments.controls; track index; let index = $index) {
      <tr>
        <td class="by-w-70 only-border-down">
          <div nz-row [nzGutter]="[0, 5]">
            <div nz-col [nzSpan]="24">
              <div>
                <strong> {{ payment.value.label }}</strong>
              </div>
              <div>
                <span>
                  {{ payment.value?.moreInfo?.customer | truncate: 20 : '...' }}
                </span>
                <span>
                  {{ payment.value?.moreInfo?.date | formatDate: 'L' }}
                </span>
              </div>
            </div>
          </div>
        </td>
        <td class="only-border-down by-w-10">
          <div class="by-flex by-gap-5 by-w-100">
            <i
              class="fas fa-arrow-alt-circle-right color--orange pointer by-pt-10"
              (click)="setMaxAmount(index)"
            ></i>

            <nz-input-number
              [style.width.px]="80"
              (nzBlur)="onTouched()"
              [nzMin]="
                payment.controls.advanceInvoiceId.value &&
                isOnlyOneAdvanceAttached
                  ? minAdvanceInvoiceAmountToSet
                  : 0
              "
              [nzMax]="maxAmountsMap[index]"
              [nzStep]="1"
              [formControl]="payment.controls.currentAmount"
            ></nz-input-number>
          </div>
          @if (
            !payment.controls.paymentMethodId.value &&
            !payment.controls.isSuspendPayment.value
          ) {
            <div class="tiny-text-of">
              <span class="by-pr-5">{{ 'of' | translate }}</span>
              <by-currency-format
                [currencyValue]="payment.controls.maxAmount.value"
              >
              </by-currency-format>
            </div>
          }
        </td>

        <td class="only-border-down by-w-5">
          @if (payment.value.currentAmount > 0) {
            @if (
              payment.controls.advanceInvoiceId.value &&
              isOnlyOneAdvanceAttached
            ) {
              <i
                class="fas fa-times-circle color--red pointer"
                [class.color--grey-light]="
                  minAdvanceInvoiceAmountToSet ===
                  payment.controls.currentAmount.value
                "
                (click)="resetSingleAmountAdvanceInvoice(index)"
              ></i>
            } @else {
              <i
                class="fas fa-times-circle color--red pointer"
                (click)="resetSingleAmount(index)"
              ></i>
            }
          }
        </td>
      </tr>
    }

    <tr class="no-hover">
      <td colspan="100%" class="border-none">
        <div nz-row class="by-pl-10">
          <div
            nz-col
            [nzSpan]="7"
            class="by-flex by-flex-direction--column by-gap-5 box-container box-container--total by-pb-10"
          >
            <div class="title">{{ 'total' | translate | upperfirst }}</div>
            <div class="by-flex by-justify-content--space-between">
              <span class="bolder by-fs-16">
                <by-currency-format [currencyValue]="maxAmountToCancel">
                </by-currency-format>
              </span>
              <i class="fal fa-money-bill-wave by-pr-10 color-total"></i>
            </div>
          </div>
          <div
            nz-col
            [nzSpan]="7"
            [nzOffset]="1"
            class="by-flex by-flex-direction--column by-gap-5 box-container box-container--paid by-pb-10"
          >
            <div class="title">{{ 'refunded' | translate | upperfirst }}</div>
            <div class="by-flex by-justify-content--space-between">
              <span class="bolder by-fs-16">
                <by-currency-format [currencyValue]="balanceToCancel">
                </by-currency-format>
              </span>
              <i class="fal fa-money-bill-wave by-pr-10 color-paid"></i>
            </div>
          </div>
          <div
            nz-col
            [nzSpan]="7"
            [nzOffset]="1"
            class="by-flex by-flex-direction--column by-gap-5 box-container box-container--balance by-pb-10"
          >
            <div class="title">{{ 'balance' | translate | upperfirst }}</div>
            <div class="by-flex by-justify-content--space-between">
              <span class="bolder by-fs-16">
                <by-currency-format
                  [currencyValue]="maxAmountToCancel - balanceToCancel"
                >
                </by-currency-format>
              </span>
              <i class="fal fa-money-bill-wave color-balance"></i>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
