import {
  Component,
  effect,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderModule } from '@app/components/page-header';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ReactiveFormsModule } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  ICreditNoteRequestRoot,
  InvoiceDetails,
  RefundPaymentsCreditNote,
} from '@app/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { CurrencyFormatComponent } from '@app/ui';
import { CreditNoteRegisterFormComponent } from '../credit-note-register-form/credit-note-register-form.component';
import { select, Store } from '@ngrx/store';
import {
  PaymentMethodsStoreActions,
  PaymentMethodsStoreSelectors,
} from '@app/root-store/payment-methods-store';
import { MediasStoreSelectors } from '@app/root-store/medias-store';
import { GetPipeModule } from '@app/pipes/get/get.module';
import { ApiOvverrideBill } from '../state/api-ovverride-bills.store';
import { calculateCityTaxMaxRefundable } from '@app/helpers/calc-city-tax-max-refundable';
import { NotificationService } from '@app/ui/services/notification.service';
import { map } from 'rxjs';
import { INVOICING_ALLOWED_PAYMENT_METHODS_ID } from '@app/config/invoicing-allowed-payment-methods.config';
import { format } from 'date-fns';
import { remove } from 'lodash';

@Component({
  selector: 'by-credit-note-register-page',
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderModule,
    NzButtonModule,
    ReactiveFormsModule,
    NzModalModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    CurrencyFormatComponent,
    CreditNoteRegisterFormComponent,
    GetPipeModule,
  ],
  templateUrl: './credit-note-register-page.component.html',
  styleUrl: './credit-note-register-page.component.scss',
  providers: [ApiOvverrideBill],
})
export class CreditNoteRegisterPageComponent implements OnInit {
  @ViewChild(CreditNoteRegisterFormComponent)
  creditNoteRegisterForm: CreditNoteRegisterFormComponent;

  private nzModalData: {
    invoice: InvoiceDetails;
    payload: Partial<ICreditNoteRequestRoot>;
  } = inject(NZ_MODAL_DATA);

  private invoicingAllowedPaymentMethodsIds =
    INVOICING_ALLOWED_PAYMENT_METHODS_ID;

  private modalRef = inject(NzModalRef);

  private store = inject(Store);

  private notification = inject(NotificationService);

  invoice: InvoiceDetails = this.nzModalData.invoice;

  payload: Partial<ICreditNoteRequestRoot> = this.nzModalData.payload;

  currentStep = signal<'total' | 'bills'>('total');

  paymentMethods$ = this.store.pipe(
    select(PaymentMethodsStoreSelectors.selectAllPaymentMethodsItems),
    map((payments) => {
      return payments.filter(({ id }) =>
        this.invoicingAllowedPaymentMethodsIds.includes(id),
      );
    }),
  );

  mediasInvoiceLayouts$ = this.store.pipe(
    select(MediasStoreSelectors.selectFlattenedLayoutsByType('invoicelayout')),
  );

  ovverrideBillStore = inject(ApiOvverrideBill);

  private translate = inject(TranslateService);

  constructor() {
    effect(() => {
      if (this.currentStep() !== 'total') {
        this.modalRef.updateConfig({ nzWidth: 900 });
        return;
      }

      this.modalRef.updateConfig({ nzWidth: 575 });
    });
  }

  ngOnInit(): void {
    this.store.dispatch(
      new PaymentMethodsStoreActions.LoadRequestAction({
        propertyId: this.invoice.property_id,
      }),
    );
  }

  generateRefundablePayments(params: {
    refundPayments: RefundPaymentsCreditNote[];
  }) {
    const { refundPayments } = params;

    return refundPayments
      .filter(
        ({ currentAmount, advanceInvoiceId }) =>
          currentAmount && !advanceInvoiceId,
      )
      .map(
        ({
          currentAmount,
          paymentMethodId,
          invoicePaymentId,
          invoicePayment,
        }) => ({
          amount: currentAmount,
          ...(invoicePayment
            ? { payment_id: invoicePaymentId, payment_method_id: null }
            : { payment_method_id: paymentMethodId, payment_id: null }),
        }),
      );
  }

  generateRefundableAdvances(refundPayments: RefundPaymentsCreditNote[]) {
    return refundPayments
      .filter(
        ({ currentAmount, advanceInvoiceId }) =>
          currentAmount && advanceInvoiceId,
      )
      .map(({ currentAmount, advanceInvoiceId }) => ({
        amount: currentAmount,
        id: advanceInvoiceId,
      }));
  }

  submit() {
    const {
      refundSameTime,
      totalValue,
      chargeAmountAligned,
      refundPayments,
      virtualStamp,
    } = this.creditNoteRegisterForm.formValues;

    const {
      isInvoiceTotalSuspended,
      isRefunbledPaymetsNotFilled,
      virtualStampCustomerCharged,
      minAdvanceInvoiceAmountToSet,
      advanceTotalSet,
      isAdvanceInvoiceAttached,
    } = this.creditNoteRegisterForm;

    if (
      (refundPayments.length > 1 && isRefunbledPaymetsNotFilled) ||
      (virtualStampCustomerCharged && isRefunbledPaymetsNotFilled)
    ) {
      this.notification.warning('credit_note_cash_out_register_warning');
      return;
    }

    if (
      isAdvanceInvoiceAttached &&
      advanceTotalSet !== minAdvanceInvoiceAmountToSet &&
      minAdvanceInvoiceAmountToSet > 0
    ) {
      this.notification.warning(
        this.translate.instant('select_min_amount_advances_of_payments', {
          amount: `€ ${minAdvanceInvoiceAmountToSet}`,
        }),
      );
      return;
    }

    const refundableSuspended = remove(refundPayments, {
      isSuspendPayment: true,
    })[0];

    const payment_date =
      refundableSuspended?.currentAmount > 0
        ? format(new Date(), 'yyyy-MM-dd')
        : '';

    const refundable_payments = this.generateRefundablePayments({
      refundPayments,
    });

    const refundable_advances = this.generateRefundableAdvances(refundPayments);

    const mark_as_paid = isInvoiceTotalSuspended ? 1 : refundSameTime;

    const partial_refund = chargeAmountAligned ? 'force_change' : 'default';

    const virtual_stamp_amount = virtualStamp?.createStamp
      ? virtualStamp.stampAmount
      : null;

    const virtual_stamp_on_customer = virtualStamp?.createStamp
      ? virtualStamp.chargeToCustomer
      : null;

    if (!this.creditNoteRegisterForm.isCreditNotePartial) {
      this.modalRef.close({
        ...this.payload,
        total: 1,
        mark_as_paid,
        virtual_stamp_amount,
        virtual_stamp_on_customer,
        ...(payment_date.length ? { payment_date } : {}),
        ...(refundable_advances.length ? { refundable_advances } : {}),
        ...(refundable_payments.length ? { refundable_payments } : {}),
      });
      return;
    }

    if (this.creditNoteRegisterForm.isReversalTypeImport) {
      this.modalRef.close({
        ...this.payload,
        total: 0,
        mark_as_paid,
        partial_refund,
        partial_computation_mode: 'fixed',
        partial_computation_value: totalValue,
        virtual_stamp_amount,
        virtual_stamp_on_customer,
        ...(payment_date.length ? { payment_date } : {}),
        ...(refundable_advances.length ? { refundable_advances } : {}),
        ...(refundable_payments.length ? { refundable_payments } : {}),
      });
      return;
    }

    this.modalRef.close({
      ...this.payload,
      exclude_taxes: false,
      total: 0,
      mark_as_paid,
      partial_refund,
      partial_computation_mode: 'custom',
      partial_computation_value: this.creditNoteRegisterForm.tableRows.total,
      bill_rows: this.creditNoteRegisterForm.tableRows.rows,
      virtual_stamp_amount,
      virtual_stamp_on_customer,
      ...(payment_date.length ? { payment_date } : {}),
      ...(refundable_advances.length ? { refundable_advances } : {}),
      ...(refundable_payments.length ? { refundable_payments } : {}),
    });
  }

  closeModal() {
    this.modalRef.close();
  }

  get isTypeCreditNoteSelected() {
    return this.creditNoteRegisterForm?.creditNoteTypeValue;
  }

  goTo(step: 'total' | 'bills') {
    this.creditNoteRegisterForm.resetPayments();

    this.currentStep.set(step);
  }

  get isReversalTypeBills() {
    return this.creditNoteRegisterForm.form.value.reversalType === 'bills';
  }

  get showGenerateCreditNoteButton() {
    return (
      this.creditNoteRegisterForm.form.value.creditNoteType === 'total' ||
      !this.isReversalTypeBills
    );
  }

  get showNextButton() {
    const { isReversalTypeBills, isCreditNotePartial } =
      this.creditNoteRegisterForm;

    return (
      this.currentStep() === 'total' &&
      isReversalTypeBills &&
      isCreditNotePartial
    );
  }

  get isOnlyCityTaxRefund() {
    return (
      this.invoice.maxRefundable === 0 &&
      calculateCityTaxMaxRefundable(this.invoice.bills_list) > 0
    );
  }
}
