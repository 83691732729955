import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, fromEvent, of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { emitMobileAppEvent, preventRequestCancellation } from '../helpers';
import { LoginRequest, LoginResponse, MobileAppEventType } from '../models';
import { CookieService } from 'ngx-cookie-service';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
  ) {}

  login(request: LoginRequest) {
    return this.http
      .post<LoginResponse>(
        'auth/login',
        {
          ...request,
          login: request.username,
        },
        { headers: { 'x-login-token': this.getLoginToken() } },
      )
      .pipe(tap(({ loginToken }) => this.checkLoginToken(loginToken)));
  }

  logout(token: string) {
    return this.http.post<LoginResponse>(
      `${API_URL}auth/logout`,
      {},
      { headers: this.getHeaders(token) },
    );
  }

  refreshTokenFromApp() {
    return defer(() => {
      return new Observable<{ token: string }>((observer) => {
        const eventName = 'getAuthToken' as MobileAppEventType;

        const listener = (event: CustomEvent<{ authToken: string }>) => {
          try {
            observer.next({ token: event.detail.authToken });
            observer.complete();
          } catch (error) {
            emitMobileAppEvent(
              'logErrors',
              JSON.stringify({
                error: JSON.stringify({ message: 'observer error', error }),
              }),
            );
          }
        };

        window.addEventListener('setToken', listener as EventListener);

        emitMobileAppEvent(
          'logErrors',
          JSON.stringify({
            error: JSON.stringify({ message: 'add listener' }),
          }),
        );

        emitMobileAppEvent(eventName);

        return () => {
          emitMobileAppEvent(
            'logErrors',
            JSON.stringify({
              error: JSON.stringify({ message: 'remove listener' }),
            }),
          );

          window.removeEventListener('setToken', listener as EventListener);
        };
      });
    });
  }

  refresh(oldToken: string, isAdmin = false): Observable<string> {
    const isMobile =
      !!this.cookieService.get('from_mobile_app') &&
      !!window['ReactNativeWebView'];

    emitMobileAppEvent(
      'logErrors',
      JSON.stringify({
        error: JSON.stringify({
          isMobile,
          from_mobile_app: this.cookieService.get('from_mobile_app'),
          ReactNativeWebView: !!window['ReactNativeWebView'],
        }),
      }),
    );

    const refreshFlow$ = isMobile
      ? this.refreshTokenFromApp()
      : this.http.get<{ token: string }>(`${API_URL}auth/refresh_token`, {
          headers: this.getHeaders(oldToken),
        });

    return preventRequestCancellation(refreshFlow$).pipe(
      map(({ token }) => {
        emitMobileAppEvent(
          'logErrors',
          JSON.stringify({
            error: JSON.stringify({ newToken: token, oldToken }),
          }),
        );
        return token;
      }),
      catchError((error: HttpErrorResponse) => {
        emitMobileAppEvent(
          'logErrors',
          JSON.stringify({ error: JSON.stringify({ error: oldToken }) }),
        );
        if (error.status === 401) {
          return throwError(null);
        }

        return of(oldToken);
      }),
    );
  }

  private getHeaders(token: string) {
    return { Authorization: 'Bearer ' + token };
  }

  private checkLoginToken(loginToken: string) {
    const currentCookiesLogin = this.getLoginToken();

    if (!currentCookiesLogin) {
      this.setLoginToken(loginToken);
      return;
    }

    const currentCookiesLoginSplit = currentCookiesLogin.split(',');

    if (!currentCookiesLoginSplit.some((value) => value === loginToken)) {
      this.setLoginToken(`${currentCookiesLoginSplit.join()},${loginToken}`);
    }
  }

  private setLoginToken(token: string): void {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 100);

    this.cookieService.set(
      'login_token',
      token,
      expirationDate,
      '/',
      null,
      null, // Default Value
      null, // Default Value
    );
  }

  private getLoginToken(): string {
    return this.cookieService.get('login_token');
  }
}
