<div
  *nzModalTitle
  class="by-flex by-flex-middle by-tableau-actions-modal-title"
  [class.by-tableau-actions-modal-title--bordered]="formComponent?.isFirstStep"
>
  <i class="by-mr-10 fas fa-pen"></i>
  <strong class="by-fs-16 by-mr-5">
    {{ params.row.data.label }}
  </strong>
  <span class="by-fs-11"
    >[{{ params.row.data.accommodation_details.name }}]</span
  >
</div>

<by-reservation-form-dates
  [hidden]="!formComponent?.isFirstStep"
  class="by-tableau-reservation-form__dates"
  [(ngModel)]="dates"
></by-reservation-form-dates>

<div class="by-tableau-reservation-form__wrapper">
  <by-spin [nzSpinning]="loading$ | async">
    <by-reservation-form
      [dates]="dates"
      [accommodation]="accommodation"
      [currency]="currency"
      [childrenRanges]="childrenRanges$ | async"
      [priceLoading]="estimating$ | async"
      [rateplans]="rateplans$ | async"
      [depositNumberLoading]="depositNextNumberLoading$ | async"
      [estimateResponse]="estimateResponse$ | async"
      [proportionalDayPriceResponse]="proportionalDayPriceResponse$ | async"
      [invoiceLayouts]="invoiceLayouts$ | async"
      [nextDepositNumber]="depositNextNumber$ | async"
      [nextDepositNumberLoading]="depositNextNumberLoading$ | async"
      [paymentMethods]="paymentMethods$ | async"
      [reservationFroms]="reservationFroms$ | async"
      [dealers]="dealers$ | async"
      [languages]="languages"
      [vatQuotes]="vatQuotes$ | async"
      [defaultInvoiceLayoutId]="defaultInvoiceLayoutId$ | async"
      [customEmailTeplates]="customEmailTeplates$ | async"
      [automaticPaymentModule]="
        !!(
          automaticPaymentModule$
          | async
          | firstOrDefault: accommodation.property_id : false
        )
      "
      [automaticPaymentSummary]="automaticPaymentSummary"
      [automaticPaymentsLoading]="automaticPaymentsLoading$ | async"
      (estimate)="onEstimate($event)"
      (proportionalDayPrice)="onProportionalDayPrice($event)"
      (loadDepositNextNumber)="onLoadDepositNextNumber($event)"
      (showEmailPreview)="onShowEmailPreview($event, emailPreviewBody)"
      (createCompany)="onCreateCompany()"
      (loadAutomaticPayments)="loadAutomaticPaymentsSummary($event)"
    ></by-reservation-form>
  </by-spin>
</div>

<div *nzModalFooter>
  <ng-container *ngIf="formComponent?.isFirstStep">
    <div class="by-flex by-flex-middle by-flex-between">
      <div>
        <by-tag-select
          [formControl]="tagControl"
          [tags]="tags$ | async"
        ></by-tag-select>
      </div>
      <div>
        <button nz-button (click)="formComponent.goToSecondStep()">
          {{ 'next' | translate | upperfirst }}
          <i class="by-ml-10 fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!formComponent?.isFirstStep">
    <div class="by-flex by-flex-middle by-flex-between">
      <div>
        <button nz-button (click)="formComponent.goToFirstStep()">
          <i class="by-mr-10 fas fa-angle-left"></i>
          {{ 'back' | translate | upperfirst }}
        </button>
      </div>
      <div>
        <button
          nz-button
          nzType="primary"
          [nzLoading]="submitting"
          (click)="onSubmit()"
        >
          {{ 'save' | translate | upperfirst }}
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #emailPreviewBody>
  <by-spin [nzSpinning]="emailPreviewLoading$ | async">
    <div
      style="min-height: 400px"
      [innerHTML]="emailPreviewBody$ | async | safeHtml"
    ></div>
  </by-spin>
</ng-template>
