import { Action, createReducer, on } from '@ngrx/store';
import { get, uniq } from 'lodash';

import { handleSplit } from '../../helpers/handle-split';
import { Customer, Reservation } from '../../models';

import * as fromActions from './actions';
import { getBookerBirthday } from './functions/get-booker-bithday';
import * as fromState from './state';

const reservationsMapper = (reservation: Reservation): Reservation => {
  const accommodation_not_exist = !!reservation.accommodations.find(
    ({ accommodation_id }) => !accommodation_id,
  );

  let leader = reservation.booker;

  if (reservation.accommodations.length === 1) {
    const guests = get(reservation.accommodations[0], 'guests', []);

    const notes = get(reservation.accommodations[0], 'notes', []);

    if (notes.length) {
      const { note } = notes.find(
        (n) => n.related_subtype === 'accommodation_customer',
      ) ?? { note: '' };
      const noteCustomer = get(reservation?.note_customer, '[0].note');
      reservation = {
        ...reservation,
        note_customer: [
          {
            note: noteCustomer ? noteCustomer + '<br/>' + note : note,
          },
        ],
      };
    }

    const mainGuest = guests.find(({ main_guest }) => main_guest);

    const mainGuestByGuestType = guests.find(
      ({ guest_type_id }) =>
        guest_type_id === 1 || guest_type_id === 2 || guest_type_id === 3,
    );

    leader =
      mainGuest?.customer ||
      mainGuestByGuestType?.customer ||
      reservation.booker;
  }

  return {
    ...reservation,
    main_customer: {
      ...leader,
      birthday_informations: getBookerBirthday(leader),
    } as Customer,
    total_details: {
      ...reservation.total_details,
      payment_status: reservation.payment_status,
    },
    total_addons: reservation.accommodations.reduce(
      (totalAddons, accommodation) =>
        (totalAddons += accommodation.addons_count),
      0,
    ),
    accommodations: handleSplit(reservation.accommodations).map((acc) => {
      const { room } = acc;

      return {
        ...acc,
        currentRoomDay: room.day,
      };
    }),
    accommodation_not_exist,
    all_reservation_properties: reservation.accommodations.reduce(
      (propertiesArray, { property_id }) =>
        (propertiesArray = uniq([...propertiesArray, property_id])),
      [],
    ),
  };
};

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items, pagination }) => {
    return fromState.featureAdapter.setAll(items.map(reservationsMapper), {
      ...state,
      isLoading: false,
      error: null,
      pagination: pagination,
    });
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadDasboardReservationsRequest, (state) => ({
    ...state,
    isDashboardLoading: true,
    error: null,
  })),
  on(
    fromActions.loadDasboardReservationsSuccess,
    (state, { items, pagination }) => ({
      ...state,
      isDashboardLoading: false,
      error: null,
      reservationsDashboard: items.map(reservationsMapper),
      reservationsDashboardPagination: pagination,
    }),
  ),
  on(fromActions.loadDasboardReservationsFailure, (state, { error }) => ({
    ...state,
    isDashboardLoading: false,
    error,
  })),
  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.deleteSuccess,
    (state, { reservationId, force_operation, remove }) => {
      if (remove) {
        return fromState.featureAdapter.removeOne(reservationId, {
          ...state,
          isLoading: false,
          error: null,
          isDashboardLoading: false,
          reservationsDashboard: (state.reservationsDashboard || []).filter(
            (reservation) => reservation.id !== reservationId,
          ),
        });
      }
      if (!force_operation) {
        return fromState.featureAdapter.updateOne(
          {
            id: reservationId,
            changes: <any>{
              ...state.entities[reservationId],
              status: 'Cancelled',
            },
          },
          {
            ...state,
            isLoading: false,
            error: null,
          },
        );
      }
      return fromState.featureAdapter.removeOne(reservationId, {
        ...state,
        isLoading: false,
        error: null,
        isDashboardLoading: false,
        reservationsDashboard: (state.reservationsDashboard || []).filter(
          (reservation) => reservation.id !== reservationId,
        ),
      });
    },
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.setReadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.setReadSuccess,
    (state, { reservationId, read, fromDashboard }) => {
      const { reservationsDashboard } = state;
      if (fromDashboard) {
        const newDashboardReservations = reservationsDashboard.map(
          (reservation) => {
            if (reservation.id === reservationId) {
              return { ...reservation, read };
            }
            return reservation;
          },
        );

        return {
          ...state,
          isLoading: false,
          isDashboardLoading: false,
          reservationsDashboard: newDashboardReservations,
        };
      }

      return fromState.featureAdapter.updateOne(
        { id: reservationId, changes: { read } },
        {
          ...state,
          isLoading: false,
          error: null,
        },
      );
    },
  ),
  on(fromActions.setReadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resendEmailRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.resendEmailSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.resendEmailFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.refreshDetailsToOtaRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.refreshDetailsToOtaSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.refreshDetailsToOtaFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.noShowRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.noShowSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.noShowFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.markCreditCardAsInvalidRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.markCreditCardAsInvalidSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.markCreditCardAsInvalidFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.setLoading, (state, { loading }) => ({
    ...state,
    isLoading: loading,
  })),
  on(fromActions.loadMissingTotalDetailsRequest, (state) => {
    return {
      ...state,
      error: null,
      isLoadingTotalDetails: true,
    };
  }),
  on(
    fromActions.loadMissingTotalDetailsSuccess,
    (
      state,
      { reservationID, totalDetails, totalDetailsLoaded, destination },
    ) => {
      if (destination === 'dashboard') {
        return {
          ...state,
          isLoadingTotalDetails: false,
          error: null,
          reservationsDashboard: [
            ...state.reservationsDashboard.map((reservation) => {
              if (reservation.id !== reservationID) {
                return reservation;
              }
              return {
                ...reservation,
                total_details: totalDetails,
                totalDetailsLoaded,
              };
            }),
          ],
        };
      }
      return fromState.featureAdapter.updateOne(
        {
          id: reservationID,
          changes: {
            total_details: totalDetails,
            totalDetailsLoaded,
          },
        },
        { ...state, isLoadingTotalDetails: false, error: null },
      );
    },
  ),
  on(fromActions.loadMissingTotalDetailsFailure, (state, { error }) => ({
    ...state,
    isLoadingTotalDetails: false,
    error,
  })),
  on(fromActions.missingTotalDetailsStopLoading, (state) => ({
    ...state,
    error: null,
    isLoadingTotalDetails: false,
  })),
  on(fromActions.resetState, () => fromState.initialState),
  on(fromActions.resetStateExcludingDashboard, (state) => ({
    ...fromState.initialState,
    isDashboardLoading: state.isDashboardLoading,
    reservationsDashboard: state.reservationsDashboard,
    reservationsDashboardPagination: state.reservationsDashboardPagination,
  })),

  on(fromActions.exportFileSuccess, (state, { exportId }) => ({
    ...state,
    exportFileId: exportId,
  })),
);

export function reservationsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
