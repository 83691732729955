import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { get } from 'lodash';
import moment from 'moment';
import { debounceTime, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { selectAllPropertiesNames } from '../../../core/+state/core.reducer';
import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { IInvoiceNextNumber } from '../../../features/commons/reservation-details/models/reservation.charges.model';
import { removeNullishValues } from '../../../helpers';
import { IInvoiceLayout, InvoiceDetails, InvoiceTypes } from '../../../models';
import {
  InvoiceNumberStoreActions,
  InvoiceNumberStoreSelectors,
} from '../../../root-store/invoice-number-store';
import * as InvoicesStoreActions from '../../../root-store/invoices-store/actions';
import * as InvoicesStoreSelectors from '../../../root-store/invoices-store/selectors';
import { RootState } from '../../../root-store/root-state';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { SDI_CHARACTERS_NOT_ALLOWED } from '@app/core/helpers/sdi-regex';

export interface InvoiceRegisterModalOutput {
  invoice_date: Date;
  invoice_layout_sectional_id: number;
  number: string;
  property_id: number;
  causal?: string;
  extra_notes?: string;
}

@Component({
  selector: 'by-invoice-register-modal',
  templateUrl: './invoice-register-modal.component.html',
  styleUrls: ['./invoice-register-modal.component.scss'],
})
export class InvoiceRegisterModalComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<InvoiceRegisterModalComponent> =
    inject(NZ_MODAL_DATA);

  @Input()
  invoice: InvoiceDetails = this.nzDataModal.invoice;

  @Input()
  propertiesIds: number[] = this.nzDataModal?.propertiesIds || [];

  @Input()
  layout: IInvoiceLayout = this.nzDataModal.layout;

  @Input()
  creditNoteOtherInfo: { causal?: string; extra_notes?: string } =
    this.nzDataModal.creditNoteOtherInfo;

  loading$ = this.store.pipe(
    select(InvoicesStoreSelectors.selectInvoicesLoadingNextNumber),
  );

  numberAlredyUsed$ = this.store.pipe(
    select(InvoiceNumberStoreSelectors.selectNumberAlredyUsed),
  );

  selectDateNumberNoSequential$ = this.store.pipe(
    select(InvoiceNumberStoreSelectors.selectDateNumberNoSequential),
  );

  propertiesNames$ = this.store.pipe(select(selectAllPropertiesNames));

  form = this.formBuilder.group({
    invoice_date: new Date(),
    invoice_layout_sectional_id: null,
    invoice_number: [null, [Validators.required]],
    property_id: [null],
    causal: [null],
    extra_notes: [null],
  });

  userWantsToChangeTheNumber = this.formBuilder.control(false);

  sdiCharactersNotAllowed = SDI_CHARACTERS_NOT_ALLOWED;

  nextNumber: IInvoiceNextNumber;

  invoiceType: InvoiceTypes = 'invoice';

  private subs = new SubSink();

  constructor(
    protected store: Store<RootState>,
    protected formBuilder: UntypedFormBuilder,
    protected dateformatter: DateFormatterService,
  ) {}

  ngOnInit() {
    // Richiesta di Dario di far visualizzare sempre la data odierna
    // if (this.invoice?.invoice_date) {
    //   this.form.patchValue({
    //     invoice_date: new Date(this.invoice?.invoice_date),
    //   });
    // }

    if (!get(this.invoice, 'layout.id')) {
      return;
    }

    if (
      this.creditNoteOtherInfo?.causal ||
      this.creditNoteOtherInfo?.extra_notes
    ) {
      this.form.patchValue(
        {
          causal: this.creditNoteOtherInfo.causal,
          extra_notes: this.creditNoteOtherInfo.extra_notes,
        },
        { emitEvent: false },
      );
    }

    this.subs.add(
      this.store
        .pipe(
          select(InvoicesStoreSelectors.selectInvoiceNextNumber),
          map((data) => data && data[this.layout.id]),
        )
        .subscribe(this.onNextNumberLoaded),
    );

    this.subs.add(
      this.invoiceLayoutSectionalControl.valueChanges.subscribe(
        (invoiceLayoutSectionalId) =>
          this.loadNextNumber(invoiceLayoutSectionalId),
      ),
    );

    this.subs.add(
      this.userWantsToChangeTheNumber.valueChanges.subscribe(
        (userWantsToChangeTheNumber: boolean) => {
          if (!userWantsToChangeTheNumber) {
            this.setExistingInvoiceNumber();
          } else {
            this.form.patchValue(
              { invoice_date: new Date() },
              { emitEvent: false },
            );
            this.loadNextNumber(this.invoiceLayoutSectionalControl.value);
          }
        },
      ),
    );

    this.subs.add(
      this.form.valueChanges.pipe(debounceTime(300)).subscribe((formValue) => {
        const { invoice_number, invoice_date, invoice_layout_sectional_id } =
          formValue;

        if (!invoice_number) {
          return;
        }

        this.store.dispatch(
          InvoiceNumberStoreActions.checkRequest({
            request: removeNullishValues({
              number: invoice_number,
              date: this.dateformatter.toServerFormat(invoice_date),
              invoice_layout_id: this.layout.id,
              invoice_layout_sectional_id: invoice_layout_sectional_id || null,
              type: this.invoiceType,
              year: (invoice_date as Date)?.getFullYear(),
            }),
          }),
        );
      }),
    );

    if (this.propertiesIds) {
      this.form.patchValue({ property_id: this.propertiesIds[0] });
    }

    if (this.invoiceHasAlredyANumber) {
      this.setExistingInvoiceNumber();
    } else {
      this.loadNextNumber();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(InvoiceNumberStoreActions.resetState());
  }

  get invoiceHasAlredyANumber(): boolean {
    return !!(
      this.invoice?.number &&
      (!this.invoice?.expiry_date_resend ||
        moment().isSameOrBefore(
          moment(this.invoice?.expiry_date_resend, 'YYYY-MM-DD'),
          'days',
        ))
    );
  }

  get invoiceLayoutSectionalControl(): AbstractControl {
    return this.form.get('invoice_layout_sectional_id');
  }

  get invoiceLayoutSectionalControlIsValid() {
    return (
      this.invoiceLayoutSectionalControl.dirty ||
      !get(this.invoice, 'layout.sectionals', []).length
    );
  }

  get value(): InvoiceRegisterModalOutput {
    const { invoice_number, causal, extra_notes, ...form } = this.form.value;

    return {
      ...form,
      number: invoice_number,
      ...(this.isCreditNote ? { causal, extra_notes } : {}),
    };
  }

  get invoiceNumber() {
    return this.form.get('invoice_number').value;
  }

  get invoiceDate() {
    return this.form.get('invoice_date').value;
  }

  private loadNextNumber(invoice_layout_sectional_id: number = null) {
    this.store.dispatch(
      new InvoicesStoreActions.LoadInvoiceNextNumberRequest({
        invoice_layout_id: this.layout.id,
        type: this.invoiceType,
        invoice_layout_sectional_id: invoice_layout_sectional_id || null,
      }),
    );
  }

  private onNextNumberLoaded = (nextNumber: IInvoiceNextNumber) => {
    this.nextNumber = get(nextNumber, [this.invoiceType, 0]);

    if (!this.nextNumber) {
      return;
    }

    const { number } = this.nextNumber;
    this.form.patchValue({ invoice_number: number });
  };

  private setExistingInvoiceNumber() {
    this.form.patchValue(
      {
        invoice_layout_sectional_id: this.invoice.sectional_id || 0,
        invoice_number: this.invoice.number,
        invoice_date: new Date(this.invoice.invoice_date),
      },
      { emitEvent: false },
    );

    this.invoiceLayoutSectionalControl.markAsDirty();
  }

  get isCreditNote() {
    return this.invoice.registered && this.invoice.type === 'invoice';
  }

  disabledStartDate = (current: Date) => {
    return moment(current).isAfter(moment(), 'day');
  };
}
