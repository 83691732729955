import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { nightsOfPeriod } from '@app/core/helpers';
import { TranslateService } from '@ngx-translate/core';
import { first, isNil, uniq, upperFirst, map as lodashMap } from 'lodash';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { map } from 'rxjs/operators';

import { AddonEstimateModalComponent } from '../../../../components/addon-estimate-modal/addon-estimate-modal.component';
import { GRID_BREAKPOINT } from '../../../../config';
import { handleFormSubmit } from '../../../../core/helpers/handleFormSubmit';
import { ICurrency } from '../../../../core/models/api/generics/currencies/currency.model';
import { IPaymentMethod } from '../../../../core/models/api/generics/payment-methods/payment-method.model';
import { INewReservation } from '../../../../core/models/api/reservation/reservaion.model';
import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import {
  JsonDateParser,
  getEstimateAddons,
  removeNullishValues,
} from '../../../../helpers';
import {
  AutomaticPaymentSummary,
  ChildrenRange,
  CompanyLookup,
  Dealer,
  EstimateRateplan,
  IAddonRoot,
  IEmailTemplateCustom,
  IInvoiceLayout,
  Language,
  PlaceVatQuote,
  ProportionalDayPrice,
  ReservationFormAccommodation,
  ReservationFrom,
} from '../../../../models';
import { EstimateAddonModalData } from '../../../../models/objects/estimate-addon-modal';
import { EstimateRequest } from '../../../../models/requests/estimate-request';
import { EstimateResponse } from '../../../../models/responses/estimate.response';
import { NotificationService } from '../../../../ui/services/notification.service';
import { ReservationFormFirstStepComponent } from '../reservation-form-first-step/reservation-form-first-step.component';
import { Price } from '../reservation-form-price/reservation-form-price.component';
import { ReservationFormSecondStepComponent } from '../reservation-form-second-step/reservation-form-second-step.component';
import { format } from 'date-fns';

enum Steps {
  First = 1,
  Second = 2,
}

type AddonsChildren = Array<{
  id: number;
  quantity: number;
}>;

@Component({
  selector: 'by-reservation-form',
  templateUrl: './reservation-form.component.html',
  styleUrls: ['./reservation-form.component.scss'],
})
export class ReservationFormComponent implements OnChanges, OnDestroy {
  @ViewChild(ReservationFormFirstStepComponent)
  firstStepComponent: ReservationFormFirstStepComponent;

  @ViewChild(ReservationFormSecondStepComponent)
  secondStepComponent: ReservationFormSecondStepComponent;

  @Input()
  dates: Date[];

  @Input()
  accommodation: ReservationFormAccommodation;

  @Input()
  currency: ICurrency;

  @Input()
  childrenRanges: ChildrenRange[];

  @Input()
  priceLoading: boolean;

  @Input()
  rateplans: EstimateRateplan[];

  @Input()
  depositNumberLoading: boolean;

  @Input()
  estimateResponse: EstimateResponse;

  @Input()
  proportionalDayPriceResponse: ProportionalDayPrice;

  @Input()
  invoiceLayouts: IInvoiceLayout[];

  @Input()
  nextDepositNumber: number;

  @Input()
  nextDepositNumberLoading: number;

  @Input()
  paymentMethods: IPaymentMethod[];

  @Input()
  reservationFroms: ReservationFrom[];

  @Input()
  dealers: Dealer[];

  @Input()
  languages: Language[];

  @Input()
  vatQuotes: PlaceVatQuote[];

  @Input()
  customEmailTeplates: IEmailTemplateCustom[];

  @Input()
  defaultInvoiceLayoutId: number;

  @Input()
  automaticPaymentSummary: AutomaticPaymentSummary[];

  @Input()
  automaticPaymentModule: boolean = false;

  @Input()
  automaticPaymentsLoading: boolean = false;

  @Output()
  estimate = new EventEmitter<EstimateRequest>();

  @Output()
  proportionalDayPrice = new EventEmitter<ProportionalDayPrice>();

  @Output()
  loadDepositNextNumber = new EventEmitter<number>();

  @Output()
  showEmailPreview = new EventEmitter<INewReservation>();

  @Output()
  createCompany = new EventEmitter();

  @Output()
  loadAutomaticPayments = new EventEmitter<{
    daily_rates: { [day: string]: number };
    discount_type_id?: 4 | 5;
    discount_value?: number;
  }>();

  currentStep = Steps.First;

  firstStepRequest: INewReservation;

  addonsCart: EstimateAddonModalData[] = [];

  isMobile$ = this.breakpointObserver
    .observe(GRID_BREAKPOINT.xSmall)
    .pipe(map(({ matches }) => matches));

  readonly Steps = Steps;

  constructor(
    private translate: TranslateService,
    private modalService: NzModalService,
    private notifications: NotificationService,
    private dateFormatter: DateFormatterService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const { dates } = changes;

    if (dates && this.dates) {
      this.onEstimate();
    }
  }

  ngOnDestroy() {}

  getForms(): UntypedFormGroup[] {
    return [
      ...this.firstStepComponent.getForms(),
      ...this.secondStepComponent.getForms(),
    ];
  }

  onEstimate(partialRequest?: Partial<EstimateRequest>) {
    const [date_from, date_to] = this.stringifyDates;

    const request: EstimateRequest = removeNullishValues({
      accommodation_id: this.accommodation.id,
      date_from,
      date_to,
      no_avail: 0,
      no_price: 0,
      ...this.firstStepComponent?.getEstimateRequest(),
      ...this.secondStepComponent?.getEstimateRequest(),
      ...partialRequest,
    });

    const requiredFields: Array<keyof EstimateRequest> = [
      'num_adults',
      'rateplan_id',
      'treatment_id',
    ];

    if (requiredFields.some((field) => isNil(request[field]))) {
      return;
    }

    this.estimate.emit(request);
  }

  onCompanySelected(company: CompanyLookup) {
    if (!company) {
      return;
    }

    const { paymentMethodIdControl } =
      this.firstStepComponent.paymentMethodComponent;

    if (paymentMethodIdControl.value === company.payment_method_id) {
      return;
    }

    paymentMethodIdControl.setValue(company.payment_method_id);

    this.notifications.success('payment_method_changed');
  }

  onPriceChanged(price: Price) {
    if (this.isFirstStep) {
      return;
    }

    this.firstStepComponent.form.patchValue({ price });

    this.onLoadAutomaticPayments();
  }

  onAddAddons() {
    const [date_from, date_to] = this.stringifyDates;

    const accommodation = first(this.firstStepRequest.accommodations);

    const propertyChildrenRanges = uniq(
      lodashMap(accommodation?.total_children, 'id') || [],
    );

    const modal: NzModalRef<AddonEstimateModalComponent, void> =
      this.modalService.create<
        AddonEstimateModalComponent,
        Partial<AddonEstimateModalComponent>
      >({
        nzTitle: upperFirst(this.translate.instant('add_bill')),
        nzContent: AddonEstimateModalComponent,
        nzData: {
          currencySymbol: this.currency?.symbol,
          vatQuotes: this.vatQuotes,
          cart: this.addonsCart,
          adultNumber: accommodation.adults_number,
          propertyChildrenRanges,
          reservationDateFrom: date_from,
          reservationDateTo: date_to,
          accommodationId: this.accommodation.id,
          accommodationPropertyId: this.accommodation.property_id,
        },
        nzWidth: '800px',
        nzFooter: [
          {
            label: upperFirst(this.translate.instant('close')),
            onClick: () => modal.close(),
          },
        ],
      });

    modal.afterClose.subscribe(() => {
      const component = modal.getContentComponent();
      this.addonsCart = component.cart || [];
      this.firstStepRequest = this.getRequest();
      this.onLoadAutomaticPayments();
    });
  }

  onLoadAutomaticPayments() {
    const price = this.firstStepComponent?.value?.price;

    if (price) {
      this.loadAutomaticPayments.emit({
        daily_rates: price.daily_rates?.reduce(
          (
            dailyRates: { [day: string]: string },
            dailyRate: { date: string; price: string },
          ) => {
            return (dailyRates = {
              ...dailyRates,
              [dailyRate.date]: dailyRate.price,
            });
          },
          {},
        ),
        discount_type_id: price?.discount_type_id,
        discount_value: price?.discount_value,
      });
    }
  }

  onShowEmailPreview() {
    this.showEmailPreview.emit(this.getRequest());
  }

  goToFirstStep() {
    this.currentStep = Steps.First;
  }

  goToSecondStep() {
    handleFormSubmit(this.firstStepComponent.getForms(), () => {
      this.currentStep = Steps.Second;
      this.firstStepRequest = this.getRequest();
    });
  }

  get isFirstStep() {
    return this.currentStep === Steps.First;
  }

  private get stringifyDates() {
    return this.dates.map((date) => this.dateFormatter.toServerFormat(date));
  }

  getRequest(): INewReservation {
    const {
      value: {
        status,
        rateplan,
        price,
        payment_method,
        guests,
        deposit,
        ...firstStepValue
      },
    } = this.firstStepComponent;

    const {
      value: { notification, payment_policy_id, ...secondStepValue },
    } = this.secondStepComponent;

    const { accommodation_tableau_number_id, property_id } = this.accommodation;

    const { daily_rates, price_total, ...priceData } = price;

    const [arrival_date, departure_date] = this.stringifyDates;

    return removeNullishValues(
      JsonDateParser.toServerFormat({
        property_id,
        number_nights: nightsOfPeriod(
          new Date(arrival_date),
          new Date(departure_date),
        ),
        price_total,
        source: 'manual',
        currency: this.currency.code,
        ...firstStepValue,
        ...secondStepValue,
        ...deposit,
        ...payment_method,
        ...status,
        ...notification,
        payment_policy_id,
        accommodations: [
          removeNullishValues({
            accommodation_id: this.accommodation.id,
            accommodation_tableau_number_id,
            daily_rates,
            property_id,
            arrival_date,
            departure_date,
            price: price_total,
            addons: this.addonsCart.length ? this.addonsCart : null,
            ...rateplan,
            ...guests,
            ...priceData,
          }),
        ],
      }),
    );
  }

  patchCompany(company: CompanyLookup) {
    this.secondStepComponent.patchCompany(company);
  }
}
