import { sortBy } from 'lodash';

export function sortByOldOrder(
  newArray: number[],
  oldArray: number[],
): number[] {
  const oldSet = new Set(oldArray);

  return [
    // Sorting according to the old array
    ...sortBy(
      newArray.filter((item) => oldSet.has(item)),
      (item) => oldArray.indexOf(item),
    ),
    // Add elements that do not exist in the old array
    ...newArray.filter((item) => !oldSet.has(item)),
  ];
}
