import { CartMode } from '@app/models/types';
import { CrmCartItem } from '@commons/crm/models/crm-cart-item';
import { CrmEstimateRequest } from '@commons/crm/models/crm-estimate-request';
import { CrmEstimateResult } from '@commons/crm/models/crm-estimate-result';
import { Action } from '@ngrx/store';

import { Hooks } from '../../../../helpers';

import { CrmCart } from '../models/crm-cart';
import { CrmConfirmPriceQuotationRequest } from '../models/crm-confirm-price-quotation-request';
import { CrmFilters } from '../models/crm-filters';
import { CrmSearchRequest } from '../models/crm-search-request';

export enum CrmActionTypes {
  ResetSearch = '[Crm] Reset Search',

  SetLoading = '[Crm] Set Loading',

  SearchRequest = '[Crm] Search Request',
  SearchSuccess = '[Crm] Search Success',
  SearchFailure = '[Crm] Search Failure',

  EstimateRequest = '[Crm] Estimate Request',
  EstimateSuccess = '[Crm] Estimate Success',
  EstimateFailure = '[Crm] Estimate Failure',

  LoadCartRequest = '[Crm] Load Cart Request',
  LoadCartSuccess = '[Crm] Load Cart Success',
  LoadCartFailure = '[Crm] Load Cart Failure',

  CreateCartRequest = '[Crm] Create Cart Request',
  CreateCartSuccess = '[Crm] Create Cart Success',
  CreateCartFailure = '[Crm] Create Cart Failure',

  UpdateCartRequest = '[Crm] Update Cart Request',
  UpdateCartSuccess = '[Crm] Update Cart Success',
  UpdateCartFailure = '[Crm] Update Cart Failure',

  EmptyCartRequest = '[Crm] Empty Cart Request',
  EmptyCartSuccess = '[Crm] Empty Cart Success',
  EmptyCartFailure = '[Crm] Empty Cart Failure',

  AddCartItemRequest = '[Crm] Add Cart Item Request',
  AddCartItemSuccess = '[Crm] Add Cart Item Success',
  AddCartItemFailure = '[Crm] Add Cart Item Failure',

  DeleteCartItemRequest = '[Crm] Delete Cart Item Request',
  DeleteCartItemSuccess = '[Crm] Delete Cart Item Success',
  DeleteCartItemFailure = '[Crm] Delete Cart Item Failure',

  UpdateCartItemRequest = '[Crm] Update Cart Item Request',
  UpdateCartItemSuccess = '[Crm] Update Cart Item Success',
  UpdateCartItemFailure = '[Crm] Update Cart Item Failure',

  UpdatePaymentPolicyRequest = '[Crm] Update Payment Policy Request',
  UpdatePaymentPolicySuccess = '[Crm] Update Payment Policy Success',
  UpdatePaymentPolicyFailure = '[Crm] Update Payment Policy Failure',

  AddMandatoryAddonsRequest = '[Crm] Add Mandatory Addons Request',
  AddMandatoryAddonsSuccess = '[Crm] Add Mandatory Addons Success',
  AddMandatoryAddonsFailure = '[Crm] Add Mandatory Addons Failure',

  DeleteMandatoryAddonsRequest = '[Crm] Delete Mandatory Addons Request',
  DeleteMandatoryAddonsSuccess = '[Crm] Delete Mandatory Addons Success',
  DeleteMandatoryAddonsFailure = '[Crm] Delete Mandatory Addons Failure',

  ProcessReservationRequest = '[Crm] Process Reservation Request',
  ProcessReservationSuccess = '[Crm] Process Reservation Success',
  ProcessReservationFailure = '[Crm] Process Reservation Failure',

  CreatePriceQuotationRequest = '[Crm] Create Price Quotation Request',
  CreatePriceQuotationSuccess = '[Crm] Create Price Quotation Success',
  CreatePriceQuotationFailure = '[Crm] Create Price Quotation Failure',

  CreateCartAndAddItemRequest = '[Crm] Create Cart And Add Item Request',

  ApplyFilters = '[Crm] Apply Filters',
}

export class ResetSearch implements Action {
  readonly type = CrmActionTypes.ResetSearch;
}

export class SearchRequest implements Action {
  readonly type = CrmActionTypes.SearchRequest;

  constructor(public payload: { request: Partial<CrmSearchRequest> }) {}
}

export class SearchSuccess implements Action {
  readonly type = CrmActionTypes.SearchSuccess;

  constructor(
    public payload: {
      searchResult: any;
      filters: {
        accommodations: number[];
        treatments: number[];
        rateplans: number[];
        company_id: number;
        adults: number;
        children: number | number[];
      };
    },
  ) {}
}

export class SearchFailure implements Action {
  readonly type = CrmActionTypes.SearchFailure;

  constructor(public payload: { error: any }) {}
}
export class EstimateRequest implements Action {
  readonly type = CrmActionTypes.EstimateRequest;

  constructor(public payload: { request: CrmEstimateRequest }) {}
}

export class EstimateSuccess implements Action {
  readonly type = CrmActionTypes.EstimateSuccess;

  constructor(
    public payload: {
      accommodationId: number;
      rateplanId: number;
      treatmentId: number;
      estimateResult: CrmEstimateResult;
    },
  ) {}
}

export class EstimateFailure implements Action {
  readonly type = CrmActionTypes.EstimateFailure;

  constructor(public payload: { error: any }) {}
}
export class SetLoading implements Action {
  readonly type = CrmActionTypes.SetLoading;
  constructor(public payload: { loading: boolean }) {}
}

export class LoadCartRequest implements Action {
  readonly type = CrmActionTypes.LoadCartRequest;

  constructor(public payload: { type: CartMode; property_group_id: number }) {}
}

export class LoadCartSuccess implements Action {
  readonly type = CrmActionTypes.LoadCartSuccess;

  constructor(
    public payload: {
      carts: {
        id: number;
        property_id: number;
        items: CrmCartItem[];
      }[];
      type: CartMode;
    },
  ) {}
}

export class LoadCartFailure implements Action {
  readonly type = CrmActionTypes.LoadCartFailure;

  constructor(public payload: { error: any }) {}
}

export class CreateCartRequest implements Action {
  readonly type = CrmActionTypes.CreateCartRequest;
  constructor(
    public payload: { type: 'cart' | 'quote'; property_group_id: number },
  ) {}
}

export class CreateCartSuccess implements Action {
  readonly type = CrmActionTypes.CreateCartSuccess;

  constructor(public payload: { cartId: number }) {}
}

export class UpdateCartRequest implements Action {
  readonly type = CrmActionTypes.UpdateCartRequest;

  constructor(
    public payload: {
      cart: CrmCart;
      processReservation: boolean;
    },
  ) {}
}

export class UpdateCartSuccess implements Action {
  readonly type = CrmActionTypes.UpdateCartSuccess;

  constructor(public payload: { cart: CrmCart }) {}
}

export class UpdateCartFailure implements Action {
  readonly type = CrmActionTypes.UpdateCartFailure;

  constructor(public payload: { error: any }) {}
}

export class CreateCartFailure implements Action {
  readonly type = CrmActionTypes.CreateCartFailure;

  constructor(public payload: { error: any }) {}
}

export class EmptyCartRequest implements Action {
  readonly type = CrmActionTypes.EmptyCartRequest;

  constructor(
    public payload: {
      cartId: number;
      shouldCreateCart: boolean;
      type: 'cart' | 'quote';
      property_group_id: number;
    },
  ) {}
}

export class EmptyCartSuccess implements Action {
  readonly type = CrmActionTypes.EmptyCartSuccess;
  constructor(public payload: { cartId: number }) {}
}

export class EmptyCartFailure implements Action {
  readonly type = CrmActionTypes.EmptyCartFailure;

  constructor(public payload: { error: any }) {}
}

export class AddCartItemRequest implements Action {
  readonly type = CrmActionTypes.AddCartItemRequest;

  constructor(
    public payload: {
      cartId: number;
      cartItem: CrmCartItem;
      onClickSuccessNotification?: () => void;
    },
  ) {}
}

export class AddCartItemSuccess implements Action {
  readonly type = CrmActionTypes.AddCartItemSuccess;

  constructor(
    public payload: {
      cartItems: Array<{
        id: number;
        booking_engine_cart_id: number;
        accommodation: CrmCartItem;
      }>;
    },
  ) {}
}

export class AddCartItemFailure implements Action {
  readonly type = CrmActionTypes.AddCartItemFailure;

  constructor(public payload: { error: any }) {}
}

export class CreateCartAndAddItemRequest implements Action {
  readonly type = CrmActionTypes.CreateCartAndAddItemRequest;
  constructor(
    public payload: {
      type: 'cart' | 'quote';
      cartItem: CrmCartItem;
      property_group_id: number;
    },
  ) {}
}

export class DeleteCartItemRequest implements Action {
  readonly type = CrmActionTypes.DeleteCartItemRequest;

  constructor(public payload: { cartId: number; cartItemId: number }) {}
}

export class DeleteCartItemSuccess implements Action {
  readonly type = CrmActionTypes.DeleteCartItemSuccess;

  constructor(public payload: { cartId: number; cartItemId: number }) {}
}

export class DeleteCartItemFailure implements Action {
  readonly type = CrmActionTypes.DeleteCartItemFailure;

  constructor(public payload: { error: any }) {}
}

export class UpdateCartItemRequest implements Action {
  readonly type = CrmActionTypes.UpdateCartItemRequest;

  constructor(
    public payload: {
      cartId: number;
      cartItemId: number;
      cartItem: CrmCartItem;
    },
  ) {}
}

export class UpdateCartItemSuccess implements Action {
  readonly type = CrmActionTypes.UpdateCartItemSuccess;

  constructor(
    public payload: {
      cartId: number;
      cartItemId: number;
      cartItem: CrmCartItem;
    },
  ) {}
}

export class UpdateCartItemFailure implements Action {
  readonly type = CrmActionTypes.UpdateCartItemFailure;

  constructor(public payload: { error: any }) {}
}

export class UpdatePaymentPolicyRequest implements Action {
  readonly type = CrmActionTypes.UpdatePaymentPolicyRequest;

  constructor(
    public payload: {
      cartId: number;
      payment_policy_id: number;
    },
  ) {}
}

export class UpdatePaymentPolicySuccess implements Action {
  readonly type = CrmActionTypes.UpdatePaymentPolicySuccess;

  constructor(
    public payload: {
      cartId: number;
      payment_policy_id: number;
    },
  ) {}
}

export class UpdatePaymentPolicyFailure implements Action {
  readonly type = CrmActionTypes.UpdatePaymentPolicyFailure;

  constructor(public payload: { error: any }) {}
}

export class ProcessReservationRequest implements Action {
  readonly type = CrmActionTypes.ProcessReservationRequest;

  constructor(public payload: { cartId: number; force_operation?: boolean }) {}
}

export class ProcessReservationSuccess implements Action {
  readonly type = CrmActionTypes.ProcessReservationSuccess;
}

export class ProcessReservationFailure implements Action {
  readonly type = CrmActionTypes.ProcessReservationFailure;

  constructor(public payload: { error: any }) {}
}

export class AddMandatoryAddonsRequest implements Action {
  readonly type = CrmActionTypes.AddMandatoryAddonsRequest;
  constructor(public payload: { cartId: number; cartItemId?: number }) {}
}
export class AddMandatoryAddonsSuccess implements Action {
  readonly type = CrmActionTypes.AddMandatoryAddonsSuccess;
  constructor(public payload: { cartId: number; cartItems: CrmCartItem[] }) {}
}
export class AddMandatoryAddonsFailure implements Action {
  readonly type = CrmActionTypes.AddMandatoryAddonsFailure;
  constructor(public payload: { error: any }) {}
}

export class DeleteMandatoryAddonsRequest implements Action {
  readonly type = CrmActionTypes.DeleteMandatoryAddonsRequest;
  constructor(public payload: { cartId: number }) {}
}
export class DeleteMandatoryAddonsSuccess implements Action {
  readonly type = CrmActionTypes.DeleteMandatoryAddonsSuccess;
  constructor(public payload: { cartId: number; cartItems: CrmCartItem[] }) {}
}
export class DeleteMandatoryAddonsFailure implements Action {
  readonly type = CrmActionTypes.DeleteMandatoryAddonsFailure;
  constructor(public payload: { error: any }) {}
}

export class CreatePriceQuotationRequest implements Action {
  readonly type = CrmActionTypes.CreatePriceQuotationRequest;

  constructor(
    public payload: {
      request: CrmConfirmPriceQuotationRequest;
      force_operation?: boolean;
      hooks?: Hooks;
    },
  ) {}
}

export class CreatePriceQuotationSuccess implements Action {
  readonly type = CrmActionTypes.CreatePriceQuotationSuccess;
}

export class CreatePriceQuotationFailure implements Action {
  readonly type = CrmActionTypes.CreatePriceQuotationFailure;

  constructor(public payload: { error: any }) {}
}

export class ApplyFilters implements Action {
  readonly type = CrmActionTypes.ApplyFilters;

  constructor(public payload: { filters: Partial<CrmFilters> }) {}
}

export type CrmActions =
  | ResetSearch
  | SearchRequest
  | SearchSuccess
  | SearchFailure
  | EstimateRequest
  | EstimateSuccess
  | EstimateFailure
  | LoadCartRequest
  | LoadCartSuccess
  | LoadCartFailure
  | CreateCartRequest
  | CreateCartSuccess
  | CreateCartFailure
  | EmptyCartRequest
  | EmptyCartSuccess
  | EmptyCartFailure
  | AddCartItemRequest
  | AddCartItemSuccess
  | AddCartItemFailure
  | CreateCartAndAddItemRequest
  | DeleteCartItemRequest
  | DeleteCartItemFailure
  | DeleteCartItemSuccess
  | UpdateCartItemRequest
  | UpdateCartItemFailure
  | UpdateCartItemSuccess
  | UpdatePaymentPolicyRequest
  | UpdatePaymentPolicySuccess
  | UpdatePaymentPolicyFailure
  | ProcessReservationRequest
  | ProcessReservationFailure
  | ProcessReservationSuccess
  | CreatePriceQuotationRequest
  | CreatePriceQuotationSuccess
  | CreatePriceQuotationFailure
  | AddMandatoryAddonsRequest
  | AddMandatoryAddonsSuccess
  | AddMandatoryAddonsFailure
  | DeleteMandatoryAddonsRequest
  | DeleteMandatoryAddonsSuccess
  | DeleteMandatoryAddonsFailure
  | UpdateCartRequest
  | UpdateCartFailure
  | UpdateCartSuccess
  | SetLoading
  | ApplyFilters;
