import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutomaticPaymentSummary, SearchParams } from '../models';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';

export type AutomaticPaymentTemplatePreviewType =
  | 'charge_succeeded'
  | 'charge_failed'
  | 'charge_reminder'
  | 'custom';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateService {
  constructor(private http: HttpClient) {}

  load(
    data: any,
    lang_iso_code: string,
    templateType: 'custom' | 'system',
    templateId: number,
    expected_payments?: AutomaticPaymentSummary['expected_payments'],
  ) {
    const dataToSend = {
      ...data,
      ...(expected_payments && {
        expected_payments: expected_payments.map((payment) => ({
          scheduled_date: payment.date,
          amount: payment.amount,
        })),
      }),
    };

    const url =
      templateType === 'system'
        ? `reservation/email_preview/system?lang=${lang_iso_code}`
        : `reservation/email_preview/custom/${templateId}?lang=${lang_iso_code}`;
    return this.http.post(url, dataToSend);
  }

  loadAutomaticPaymentsPreview(
    property_id: number,
    template_type: AutomaticPaymentTemplatePreviewType,
    templateId?: number,
  ) {
    const customUrl = `${template_type === 'custom' ? `email_template_custom/${templateId}` : `email_template_system/${template_type}`}`;

    return this.http.get<IResponseSuccess<{ html: string }>>(
      `property/${property_id}/payments/${customUrl}/preview`,
    );
  }
}
