<nz-table
  nzTemplateMode
  [nzShowPagination]="false"
  class="credit-note-table-top by-pl-5"
>
  <tbody>
    <tr [class.custom-border]="detailRowOpen()">
      <td
        [nzShowCheckbox]="!detailRowOpen() && !taxRowIsOpen()"
        [nzChecked]="groupRowIsSelected"
        (nzCheckedChange)="updateGroupRowCheck($event)"
        class="checkbox-size horizontal-borderless"
        [class.dashed-column]="detailRowOpen() || taxRowIsOpen()"
      ></td>

      <td class="horizontal-borderless" [class.border-none]="detailRowOpen()">
        {{ form.value.groupLabel }}
        @if (form.value.type === 'tax') {
          <i
            class="pointer by-pl-10"
            [class.fal]="!taxRowIsOpen()"
            [class.fa-edit]="!taxRowIsOpen()"
            [class.color--azure]="!taxRowIsOpen()"
            [class.far]="taxRowIsOpen()"
            [class.fa-undo]="taxRowIsOpen()"
            [class.color--orange]="taxRowIsOpen()"
            nz-tooltip
            [nzTooltipTitle]="
              taxRowIsOpen()
                ? ('restore_total_edit_by_category' | translate | upperfirst)
                : ('edit_single_bills_by_category' | translate | upperfirst)
            "
            nz-popconfirm
            [nzPopconfirmTrigger]="taxRowIsOpen() ? 'click' : null"
            [nzPopconfirmTitle]="
              'restore_total_edit_by_category' | translate | upperfirst
            "
            (nzOnConfirm)="toogleCityTaxRowDetail()"
            (click)="!taxRowIsOpen() ? toogleCityTaxRowDetail() : null"
          ></i>
        } @else {
          <i
            class="pointer by-pl-10"
            [class.fal]="!detailRowOpen()"
            [class.fa-edit]="!detailRowOpen()"
            [class.color--azure]="!detailRowOpen()"
            [class.far]="detailRowOpen()"
            [class.fa-undo]="detailRowOpen()"
            [class.color--orange]="detailRowOpen()"
            nz-tooltip
            [nzTooltipTitle]="
              detailRowOpen()
                ? ('restore_total_edit_by_category' | translate | upperfirst)
                : ('edit_single_bills_by_category' | translate | upperfirst)
            "
            nz-popconfirm
            [nzPopconfirmTrigger]="detailRowOpen() ? 'click' : null"
            [nzPopconfirmTitle]="
              'restore_total_edit_by_category' | translate | upperfirst
            "
            (nzOnConfirm)="toogleOpeningRowDetail()"
            (click)="!detailRowOpen() ? toogleOpeningRowDetail() : null"
          ></i>
        }
      </td>

      <td class="by-w-10 horizontal-borderless">
        <by-currency-format [currencyValue]="form.value.groupMaxRefundable">
        </by-currency-format>
      </td>

      <td class="by-w-25 horizontal-borderless">
        @if (!detailRowOpen() && !taxRowIsOpen() && groupRowIsSelected) {
          <div class="by-flex by-flex-end">
            <div class="by-w-70">
              <by-credit-note-register-import-number
                [maxValue]="form.value.groupMaxRefundable"
                [formControl]="form.controls.groupAmount"
                [disabled]="form.value.type === 'tax'"
              />
              <div
                class="by-w-100 by-pt-1 color--gray by-flex by-flex-end color--grey-dark font-tiny"
              >
                @if (form.value.groupAmount.type === 'percentage') {
                  <by-currency-format
                    [currencyValue]="form.value.groupAmount.amount"
                  >
                  </by-currency-format>
                }
              </div>
            </div>
          </div>
        }
        @if (detailRowOpen() || taxRowIsOpen()) {
          <div class="by-flex by-flex-end">
            <by-currency-format
              [currencyValue]="form.value.rows | generateGroupTotals"
            >
            </by-currency-format>
          </div>
        }
      </td>
    </tr>

    @if (detailRowOpen()) {
      <tr class="credit-note-expand-table by-w-100">
        <td colspan="100%">
          <nz-table
            #detailRowTable
            [nzData]="rowControls"
            [nzShowPagination]="false"
            class="table-no-padding"
          >
            <thead>
              <tr>
                <th class="dashed-row-column border-none"></th>
                <th
                  class="checkbox-size border-none"
                  [nzChecked]="rowValues | every: isRowSelected"
                  (nzCheckedChange)="setAllRowCheckbox($event)"
                  [nzIndeterminate]="
                    (rowValues | some: isRowSelected) &&
                    !(rowValues | every: isRowSelected)
                  "
                ></th>
                <th class="color--azure border-none">
                  {{ 'description' | translate | uppercase }}
                </th>
                <th class="color--azure border-none by-w-10">
                  {{ 'import' | translate | uppercase }}
                </th>

                <th class="color--azure border-none by-w-5">
                  {{ 'quantity_short' | translate | uppercase }}
                </th>
                <th class="color--azure border-none by-w-10">
                  {{ 'total' | translate | uppercase }}
                  <i
                    class="far fa-info-circle by-pl-1 pointer"
                    nz-tooltip
                    [nzTooltipTitle]="
                      'total_shows_max_to_cancel' | translate | upperfirst
                    "
                  ></i>
                </th>
                <th class="color--azure border-none by-w-20 text-align--end">
                  {{ 'reverse' | translate | uppercase }}
                </th>
              </tr>
            </thead>
            <tbody>
              @for (
                rowControl of rowControls;
                track rowControl.value.rowId;
                let rowIndex = $index
              ) {
                <tr>
                  <td class="dashed-row-column border-none"></td>
                  <td
                    [nzChecked]="rowControl.value.selected"
                    (nzCheckedChange)="
                      setSingleRowSelection($event, rowControl)
                    "
                    class="checkbox-size border-none"
                  ></td>
                  <td class="only-border-down">
                    {{ rowControl.value.rowLabel }}
                  </td>
                  <td class="only-border-down by-w-10 text-align--center">
                    <by-currency-format
                      [currencyValue]="
                        rowControl.value.rowMaxRefundable /
                        rowControl.value.rowMaxQty
                      "
                    >
                    </by-currency-format>
                  </td>
                  <td class="only-border-down by-w-5 text-align--center">
                    @if (
                      rowControl.value.selected &&
                      rowControl.value.rowMaxQty > 1
                    ) {
                      <nz-input-number
                        (ngModelChange)="changeRowQty($event, rowControl)"
                        [formControl]="rowControl.controls.rowQty"
                        [nzMin]="1"
                        [nzMax]="rowControl.value.rowMaxQty"
                        [nzStep]="1"
                        style="width: 50px"
                      ></nz-input-number>
                    } @else {
                      {{ rowControl.value.rowMaxQty }}
                    }
                  </td>
                  <td class="only-border-down by-w-10 text-align--center">
                    <by-currency-format
                      [currencyValue]="rowControl.value.rowMaxRefundable"
                    >
                    </by-currency-format>
                  </td>
                  <td class="only-border-down by-w-20">
                    <div class="by-flex by-flex-end">
                      <div class="by-w-90">
                        @if (rowControl.value.selected) {
                          <by-credit-note-register-import-number
                            [maxValue]="
                              (rowControl.value.rowMaxRefundable /
                                rowControl.value.rowMaxQty) *
                              rowControl.value.rowQty
                            "
                            [formControl]="rowControl.controls.rowAmount"
                          ></by-credit-note-register-import-number>
                        }
                        <div
                          class="by-w-100 by-pt-1 color--gray by-flex by-flex-end color--grey-dark font-tiny"
                        >
                          @if (
                            rowControl.value.rowAmount.type === 'percentage' &&
                            rowControl.value.selected
                          ) {
                            <by-currency-format
                              [currencyValue]="
                                rowControl.value.rowAmount.amount
                              "
                            >
                            </by-currency-format>
                          }
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              }</tbody
          ></nz-table>
        </td>
      </tr>
    }

    @if (taxRowIsOpen()) {
      <tr class="credit-note-expand-table by-w-100">
        <td colspan="100%">
          <nz-table
            #detailCityTaxRows
            [nzShowPagination]="false"
            nzTemplateMode
            class="table-no-padding"
          >
            <thead>
              <tr>
                <th class="dashed-row-column border-none"></th>
                <th
                  class="checkbox-size border-none"
                  [nzChecked]="rowValues | every: isRowSelected"
                  (nzCheckedChange)="setAllRowCheckbox($event)"
                  [nzIndeterminate]="
                    (rowValues | some: isRowSelected) &&
                    !(rowValues | every: isRowSelected)
                  "
                ></th>

                <th class="color--azure border-none">
                  {{ 'customer' | translate | upperfirst }}
                </th>

                <th class="color--azure border-none">
                  {{ 'reservation' | translate | upperfirst }}
                </th>

                <th class="color--azure border-none">
                  {{ 'date' | translate | upperfirst }}
                </th>

                <th class="color--azure border-none">
                  {{ 'import' | translate | upperfirst }}
                </th>
              </tr>
            </thead>
            <tbody>
              @for (
                cityTaxRowControl of rowControls;
                track cityTaxRowControl.value.rowId;
                let rowIndex = $index
              ) {
                <tr>
                  <td class="dashed-row-column border-none"></td>
                  <td
                    [nzChecked]="cityTaxRowControl.value.selected"
                    (nzCheckedChange)="
                      setSingleRowSelection($event, cityTaxRowControl)
                    "
                    class="checkbox-size border-none"
                  ></td>

                  <td class="border-none">
                    <div class="by-flex by-gap-5">
                      <span>{{
                        cityTaxMaxRefundablesMap[cityTaxRowControl.value.rowId]
                          ?.buyer_surname
                      }}</span>

                      <span>{{
                        cityTaxMaxRefundablesMap[cityTaxRowControl.value.rowId]
                          ?.buyer_name
                      }}</span>
                    </div>
                  </td>

                  <td class="border-none by-w-20">
                    #{{
                      cityTaxMaxRefundablesMap[cityTaxRowControl.value.rowId]
                        ?.reservation_reference
                    }}
                  </td>

                  <td class="border-none by-w-20">
                    {{
                      cityTaxMaxRefundablesMap[cityTaxRowControl.value.rowId]
                        ?.date | formatDate: 'L'
                    }}
                  </td>

                  <td class="border-none by-w-10">
                    <by-currency-format
                      [currencyValue]="
                        cityTaxMaxRefundablesMap[cityTaxRowControl.value.rowId]
                          ?.max_refundable
                      "
                    >
                    </by-currency-format>
                  </td>
                </tr>
              }
            </tbody>
          </nz-table>
        </td>
      </tr>
    }
  </tbody>
</nz-table>
