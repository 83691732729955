import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';

import { IAddonRequestRoot } from './../models/objects/addons';
import { removeNullishValues } from '@app/helpers';

@Injectable({
  providedIn: 'root',
})
export class AddonsService {
  constructor(private http: HttpClient) {}

  loadAccommodationAddons(params: {
    accommodationId: number;
    adults_number: number;
    property_children_ranges?: number[];
    reference_date?: string;
  }) {
    const {
      accommodationId,
      adults_number,
      property_children_ranges,
      reference_date,
    } = params || {};

    const propertyChildrenRange = property_children_ranges?.length
      ? `&${generateParamArray('property_children_ranges', property_children_ranges)}`
      : '';

    return this.http.get(
      `accommodation/${accommodationId}/addons?${generateSearchQuery(removeNullishValues({ adults_number, reference_date }))}${propertyChildrenRange}`,
    );
  }

  load(propertiesIds: number[]) {
    return this.http.get(
      `properties/addons?${generateParamArray('property_id', propertiesIds)}`,
    );
  }

  loadAddonsInvoice(invoiceDestinationId: number) {
    return this.http.get(`invoices/${invoiceDestinationId}/unrefunded`);
  }

  create(data: IAddonRequestRoot) {
    return this.http.post(`property/${data.propertiesIds[0]}/addons`, data);
  }

  delete(addonId: number) {
    return this.http.delete(`addon/${addonId}`);
  }

  update(data: IAddonRequestRoot) {
    return this.http.put(
      `property/${data.propertiesIds[0]}/addons/${data.id}`,
      data,
    );
  }

  loadAddonsByPropertyCategory(
    propertyIds: number[],
    withTrashed = false,
    withManual = false,
  ) {
    return this.http.get(
      `properties/categories/addons?${generateParamArray(
        'property_id',
        propertyIds,
      )}&with_trashed=${withTrashed}&with_manual_addon=${withManual}`,
    );
  }
}
