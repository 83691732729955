import { Injectable } from '@angular/core';
import { ICoreState } from '@app/core/+state/core.reducer';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { InvoicesService } from '@app/services/invoices.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { capitalize, upperFirst } from 'lodash';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { InvoiceDetails } from '../../models';
import { InvoiceRegisterServiceParams } from '../../models/objects/invoice-register-service-params';
import { InvoiceWidgets } from '../../models/objects/invoice-widgets.model';
import { ExportService } from '../../services/export.service';
import { WarningConfirmModalService } from '../../services/warning-confirm-modal.service';
import { InvoiceRegisterResolverService } from '../../shared/invoice/services/invoice-register-resolver.service';

import * as featureActions from './actions';
import { effectHooks } from '@app/helpers';

@Injectable()
export class InvoicesStoreEffects {
  constructor(
    private dataService: InvoicesService,
    private invoiceRegisterResolverService: InvoiceRegisterResolverService,
    private actions$: Actions,
    private notifications: NotificationService,
    private translate: TranslateService,
    private store: Store<{ core: ICoreState }>,
    private errorHandler: ErrorHandlerService,
    private exportService: ExportService,
    private message: NzMessageService,
    private warningModalService: WarningConfirmModalService,
  ) {}

  loadInvoiceNextNumber$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadInvoiceNextNumberRequest>(
        featureActions.ActionTypes.LOAD_INVOICE_NEXT_NUMBER_REQUEST,
      ),
      mergeMap((action: featureActions.LoadInvoiceNextNumberRequest) => {
        const { invoice_layout_id, type, invoice_layout_sectional_id, year } =
          action.payload;
        return this.dataService
          .loadInvoiceNextNumber(
            invoice_layout_id,
            type,
            invoice_layout_sectional_id,
            year,
          )
          .pipe(
            map(
              (response: IResponseSuccess) =>
                new featureActions.LoadInvoiceNextNumberSuccess({
                  data: response.data,
                  invoice_layout_id,
                  type,
                }),
            ),
            catchError((error: any) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.LoadInvoiceNextNumberFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  registerInvoiceEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.RegisterInvoiceRequest>(
        featureActions.ActionTypes.REGISTER_INVOICE_REQUEST,
      ),
      switchMap((action: featureActions.RegisterInvoiceRequest) => {
        const { layout, registerType, invoiceCashOut } = action.payload;
        const isFiscalPrinterRegister =
          layout?.fiscalprinters?.length && registerType === 'receipt';

        const registerParams = {
          ...action.payload,
          registerService: this.dataService,
        };

        return this.invoiceRegisterResolverService
          .register(registerParams)
          .pipe(
            map((invoice) => {
              if (!isFiscalPrinterRegister) {
                this.exportService.printInvoice({
                  invoice_id: invoice.id,
                  download: 0,
                  invoiceCashOut,
                });
              }

              return new featureActions.RegisterInvoiceSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.RegisterInvoiceFailure({ error }));
            }),
          );
      }),
    ),
  );

  loadDetailsRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadDetailsRequestAction>(
        featureActions.ActionTypes.LOAD_DETAIL_REQUEST,
      ),
      switchMap((action: featureActions.LoadDetailsRequestAction) => {
        return this.dataService.loadDetail(action.payload.invoice_id).pipe(
          map(
            (response: any) =>
              new featureActions.LoadDetailsSuccessAction({
                items: response.data[0],
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadDetailsFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadByStatusSdiEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadByStatusSdiRequest>(
        featureActions.ActionTypes.LOAD_BY_STATUS_SDI_REQUEST,
      ),
      switchMap((action: featureActions.LoadByStatusSdiRequest) => {
        return this.dataService
          .loadInvoicesBySdiStatus(action.payload.params)
          .pipe(
            map((response: any) => {
              return new featureActions.LoadByStatusSdiSuccess({
                invoices: response.data,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.LoadByStatusSdiFailure({ error }));
            }),
          );
      }),
    ),
  );

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadRequestAction,
          { core: ICoreState },
        ]) => {
          let { property_id } = action.payload;

          if (!property_id) {
            property_id = store.core.allProperties.map(({ id }) => id);
          }

          return this.dataService.load({ ...action.payload, property_id }).pipe(
            map(
              (response: any) =>
                new featureActions.LoadSuccessAction({
                  items: response.data,
                  pagination: response.meta.pagination,
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.LoadFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  loadDetailShowRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadDetailsShowRequestAction>(
        featureActions.ActionTypes.LOAD_DETAIL_SHOW_REQUEST,
      ),
      switchMap((action: featureActions.LoadDetailsShowRequestAction) => {
        return this.dataService.loadDetailShow(action.payload.invoice_id).pipe(
          map(
            (response: any) =>
              new featureActions.LoadDetailsShowSuccessAction({
                items: response,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(
              new featureActions.LoadDetailsShowFailureAction({ error }),
            );
          }),
        );
      }),
    ),
  );

  loadTemplateEmailRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadEmailTemplateRequestAction>(
        featureActions.ActionTypes.LOAD_EMAIL_TEMPLATE_REQUEST,
      ),
      switchMap((action: featureActions.LoadEmailTemplateRequestAction) => {
        const { invoice_id } = action.payload;
        return this.dataService.loadTemplateEmail(invoice_id).pipe(
          map(
            (response: any) =>
              new featureActions.LoadEmailTemplateSuccessAction({
                data: response.data[0],
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(
              new featureActions.LoadEmailTemplateFailureAction({ error }),
            );
          }),
        );
      }),
    ),
  );

  loadStatusSdiInfoRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadStatusSdiInfoRequestAction>(
        featureActions.ActionTypes.LOAD_STATUS_SDI_INFO_REQUEST,
      ),
      switchMap(() => {
        return this.dataService.loadStatusSdiInfo().pipe(
          map(
            (response: any) =>
              new featureActions.LoadStatusSdiInfoSuccessAction({
                items: response.data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(
              new featureActions.LoadStatusSdiInfoFailureAction({ error }),
            );
          }),
        );
      }),
    ),
  );

  createCreditNoteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateCreditNoteRequestAction>(
        featureActions.ActionTypes.CREATE_CREDIT_NOTE_REQUEST,
      ),
      switchMap((action: featureActions.CreateCreditNoteRequestAction) => {
        const { invoice, layout } = action.payload;

        const registerParams: InvoiceRegisterServiceParams = {
          invoice,
          layout,
          registerService: this.dataService,
          registerType: 'credit_note',
        };

        return this.invoiceRegisterResolverService
          .register(registerParams)
          .pipe(
            map((creditNote: InvoiceDetails) => {
              const label = !!invoice.source_receipt
                ? 'reversal_receipt'
                : 'credit_note';

              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.create_success',
                  {
                    param: this.translate.instant(label),
                  },
                ),
                type: 'success',
              });

              if (!creditNote.printer_id) {
                this.exportService.printInvoice({
                  invoice_id: creditNote.id,
                  download: 0,
                  title: capitalize(this.translate.instant(label)),
                });
              }

              return new featureActions.CreateCreditNoteSuccessAction({
                creditNote,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.CreateCreditNoteFailureAction({ error }),
              );
            }),
          );
      }),
    ),
  );

  createReverseAutoinvoiceRequestEffect$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<featureActions.CreateReverseAutoinvoiceRequestAction>(
          featureActions.ActionTypes.CREATE_REVERSE_AUTOINVOICE_REQUEST,
        ),
        switchMap(
          (action: featureActions.CreateReverseAutoinvoiceRequestAction) => {
            const { invoice, layout } = action.payload;

            const registerParams: InvoiceRegisterServiceParams = {
              invoice,
              layout,
              registerService: this.dataService,
              registerType: 'reverse_auto_invoice',
            };

            return this.invoiceRegisterResolverService
              .register(registerParams)
              .pipe(
                map((reverseAutoinvoice: InvoiceDetails) => {
                  const label = 'auto_invoice';

                  this.notifications.push({
                    title: this.translate.instant('done'),
                    content: this.translate.instant(
                      'notifications.create_success',
                      {
                        param: this.translate.instant(label),
                      },
                    ),
                    type: 'success',
                  });

                  if (!reverseAutoinvoice.printer_id) {
                    this.exportService.printInvoice({
                      invoice_id: reverseAutoinvoice.id,
                      download: 0,
                      title: capitalize(this.translate.instant(label)),
                    });
                  }

                  return new featureActions.CreateReverseAutoinvoiceSuccessAction(
                    {
                      reverseAutoinvoice,
                    },
                  );
                }),
                catchError((error) => {
                  this.errorHandler.handle(error);
                  return of(
                    new featureActions.CreateReverseAutoinvoiceFailureAction({
                      error,
                    }),
                  );
                }),
              );
          },
        ),
      ),
  );

  resendSdiRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.ResendSdiRequestAction>(
        featureActions.ActionTypes.RESEND_SDI_REQUEST,
      ),
      switchMap((action: featureActions.ResendSdiRequestAction) => {
        const request = action.payload;
        return this.dataService.resendSdi(request).pipe(
          map((_) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('resended_sdi'),
              type: 'success',
            });

            return new featureActions.ResendSdiSuccessAction();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(
              new featureActions.CreateCreditNoteFailureAction({ error }),
            );
          }),
        );
      }),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap((action: featureActions.DeleteRequestAction) => {
        const { invoiceId, forceOperation } = action.payload;
        return this.dataService.delete(invoiceId, forceOperation).pipe(
          map((response: IResponseSuccess<{}>) => {
            if (response?.meta?.confirm_required) {
              const { warnings } = response.meta;

              const actionToDispatch = new featureActions.DeleteRequestAction({
                invoiceId,
                forceOperation: true,
              });

              const discardAction = new featureActions.DeleteFailureAction({
                error: null,
              });

              return new featureActions.OpenWarningsModalRequestAction({
                actionToDispatch,
                discardAction,
                message: warnings.toString(),
              });
            }
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.delete_success', {
                param: this.translate.instant('document'),
              }),
              type: 'success',
            });
            return new featureActions.DeleteSuccessAction({
              invoiceId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.DeleteFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap((action: featureActions.UpdateRequestAction) => {
        const {
          request: { invoice_id, ...request },
          hooks,
          force_operation,
        } = action.payload;

        const messageRef = this.createLoading('invoice_updating');

        const onFailure = (error: any) => {
          this.removeLoading(messageRef);
          this.errorHandler.handle(error);
        };

        return this.dataService
          .update(invoice_id, request, force_operation)
          .pipe(
            effectHooks(hooks),
            mergeMap((response) => {
              if (response.meta.confirm_required) {
                this.removeLoading(messageRef);

                return of(
                  new featureActions.OpenWarningsModalRequestAction({
                    actionToDispatch: new featureActions.UpdateRequestAction({
                      ...action.payload,
                      force_operation: true,
                    }),
                    discardAction: new featureActions.UpdateFailureAction({
                      error: 'confirm_required failure',
                      hooks: { onFailure },
                    }),
                    message: response.meta.warnings.toString(),
                  }),
                );
              }

              const noReload = action.payload.noReload;

              this.removeLoading(messageRef);

              if (!noReload) {
                this.notifications.push({
                  title: this.translate.instant('done'),
                  content: this.translate.instant(
                    'notifications.update_success',
                    {
                      param: this.translate.instant('document'),
                    },
                  ),
                  type: 'success',
                });
              }

              const actions: Action[] = [
                new featureActions.UpdateSuccessAction({
                  invoice: response.data[0],
                  noReload,
                }),
              ];

              return actions;
            }),
            catchError((error) => {
              onFailure(error);

              return of(
                new featureActions.UpdateFailureAction({
                  error,
                }),
              );
            }),
          );
      }),
    ),
  );

  sendEmailRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SendEmailRequestAction>(
        featureActions.ActionTypes.SEND_EMAIL_REQUEST,
      ),
      switchMap((action: featureActions.SendEmailRequestAction) => {
        return this.dataService.sendEmail(action.payload.request).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('email_sended'),
              type: 'success',
            });
            return new featureActions.SendEmailSuccessAction();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.SendEmailFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  overrideInvoice$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.OverrideBillInvoiceRequest>(
        featureActions.ActionTypes.OVERRIDE_BILL_INVOICE_REQUEST,
      ),
      mergeMap((action: featureActions.OverrideBillInvoiceRequest) => {
        const { data, edit, reservationsBillsOverrideIdMerge, noReload } =
          action.payload;

        const messageRef = this.createLoading('invoice_updating');

        return this.dataService
          .overrideInvoice(data, edit, reservationsBillsOverrideIdMerge)
          .pipe(
            mergeMap(() => {
              this.removeLoading(messageRef);

              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('document'),
                  },
                ),
                type: 'success',
              });
              return [
                new featureActions.OverrideBillInvoiceSuccess({ noReload }),
              ];
            }),
            catchError((error: any) => {
              this.removeLoading(messageRef);

              this.errorHandler.handle(error);

              return of(
                new featureActions.OverrideBillInvoiceFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  deleteOverrideInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteBillOverrideInvoiceRequest>(
        featureActions.ActionTypes.DELETE_OVERRIDE_BILL_INVOICE_REQUEST,
      ),
      mergeMap((action: featureActions.DeleteBillOverrideInvoiceRequest) => {
        const { bill_override_id } = action.payload;

        const messageRef = this.createLoading('invoice_updating');

        return this.dataService.deleteOverride(bill_override_id).pipe(
          map(() => {
            this.removeLoading(messageRef);

            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('document'),
              }),
              type: 'success',
            });
            return new featureActions.DeleteBillOverrideInvoiceSucccess();
          }),
          catchError((error: any) => {
            this.removeLoading(messageRef);

            this.errorHandler.handle(error);

            return of(
              new featureActions.DeleteBillOverrideInvoiceFailure({ error }),
            );
          }),
        );
      }),
    ),
  );

  mergeInvoicesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.MergeInvoicesRequestAction>(
        featureActions.ActionTypes.MERGE_INVOICES_REQUEST,
      ),
      switchMap((action: featureActions.MergeInvoicesRequestAction) => {
        const {
          invoice_id_destination,
          invoice_id_source,
          reservation_accommodation_id,
          hooks,
        } = action.payload;

        return this.dataService
          .mergeInvoices({
            invoice_id_destination,
            invoice_id_source,
            reservation_accommodation_id,
          })
          .pipe(
            effectHooks(hooks),
            map((response: any) => {
              const { warnings } = response.meta;

              if (warnings?.length) {
                warnings.forEach((warning) => {
                  this.notifications.push({
                    title: capitalize(this.translate.instant('attenction')),
                    content: warning,
                    type: 'warning',
                  });
                });
                return new featureActions.MergeInvoicesSuccessAction();
              }

              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant('charges_merged_success'),
                type: 'success',
              });
              return new featureActions.MergeInvoicesSuccessAction();
            }),
            catchError((error: any) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.MergeInvoicesFailureAction({ error }),
              );
            }),
          );
      }),
    ),
  );

  loadWidgetsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadWidgetsRequestAction>(
        featureActions.ActionTypes.LOAD_WIDGETS_REQUEST,
      ),
      switchMap((action: featureActions.LoadWidgetsRequestAction) => {
        const { widgetType, filters, periods } = action.payload;
        return this.dataService.loadWidgets(widgetType, filters, periods).pipe(
          map(({ data: widgets }: IResponseSuccess<InvoiceWidgets>) => {
            return new featureActions.LoadWidgetsSuccessAction({ widgets });
          }),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadWidgetsFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  restoreInvoiceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.RestoreInvoiceRequestAction>(
        featureActions.ActionTypes.RESTORE_INVOICE_REQUEST,
      ),
      switchMap((action: featureActions.RestoreInvoiceRequestAction) => {
        const { invoiceId } = action.payload;

        const messageRef = this.createLoading('invoice_updating');

        return this.dataService.restoreInvoice(invoiceId).pipe(
          map((response: IResponseSuccess) => {
            this.removeLoading(messageRef);

            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('invoice_restore_success'),
              type: 'success',
            });
            return new featureActions.RestoreInvoiceSuccessAction({
              restoredId: response.data.restored?.id || null,
            });
          }),
          catchError((error: any) => {
            this.removeLoading(messageRef);

            this.errorHandler.handle(error);
            return of(
              new featureActions.RestoreInvoiceFailureAction({ error }),
            );
          }),
        );
      }),
    ),
  );

  openWanringsModal$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<featureActions.OpenWarningsModalRequestAction>(
          featureActions.ActionTypes.OPEN_INVOICE_WARNINGS_MODAL_REQUEST,
        ),
        map((action: featureActions.OpenWarningsModalRequestAction) => {
          const { actionToDispatch, discardAction, message } = action.payload;

          this.warningModalService.open({
            message,
            action: actionToDispatch,
            discardAction,
          });
        }),
      ),
    { dispatch: false },
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.EXPORT_FILE_REQUEST),
      switchMap(({ payload: { params } }) =>
        this.dataService.exportFile(params).pipe(
          map(({ data }) => {
            return new featureActions.ExportFileSuccess({
              exportFileId: data.export_ids[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(new featureActions.ExportFileFailure({ error }));
          }),
        ),
      ),
    ),
  );

  private createLoading(message: string) {
    return this.message.loading(upperFirst(this.translate.instant(message)), {
      nzDuration: 0,
    });
  }

  private removeLoading(messageRef: NzMessageRef) {
    this.message?.remove(messageRef.messageId);
  }
}
