import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import {
  FiscalPrinter,
  IInvoiceLayout,
  IInvoiceOverride,
  Invoice,
  InvoiceCashOut,
  InvoiceDetails,
  InvoiceListExportFileRequest,
  InvoiceNextNumberRequest,
  InvoiceTotals,
  InvoiceTypes,
  InvoiceWidgetsPeriod,
  InvoiceWidgetTypes,
  RegisterInvoice,
  SdiInfo,
  SendMailInvoiceRequest,
} from '@app/models';
import { Action } from '@ngrx/store';

import { InvoiceWidgets } from '../../models/objects/invoice-widgets.model';
import { SearchParams } from '../../models/objects/search-params';
import { ActiveChargeUpdateListItemRequest } from '../../models/requests/active-charges-update-list-item-request';

import { ResendSdi } from './../../models/objects/invoices';
import { Hooks } from '@app/helpers';

export enum ActionTypes {
  REGISTER_INVOICE_REQUEST = '[Invoices] Register Invoice Request',
  REGISTER_INVOICE_SUCCESS = '[Invoices] Register Invoice Success',
  REGISTER_INVOICE_WARNING = '[Invoices] Register Invoice Warning',
  REGISTER_INVOICE_FAILURE = '[Invoices] Register Invoice Failure',

  LOAD_INVOICE_NEXT_NUMBER_REQUEST = '[Invoices] Load Invoice Nex Number Request',
  LOAD_INVOICE_NEXT_NUMBER_SUCCESS = '[Invoices] Load Invoice Nex Number Success',
  LOAD_INVOICE_NEXT_NUMBER_FAILURE = '[Invoices] Load Invoice Nex Number Failure',

  LOAD_PRINT_DETAIL_REQUEST = '[Invoices] Load Print Details Request',
  LOAD_PRINT_DETAIL_SUCCESS = '[Invoices] Load Print Details Success',
  LOAD_PRINT_DETAIL_FAILURE = '[Invoices] Load Print Details Failure',

  UPDATE_PRINT_DETAIL_REQUEST = '[Invoices] Update Print Details Request',
  UPDATE_PRINT_DETAIL_SUCCESS = '[Invoices] Update Print Details Success',
  UPDATE_PRINT_DETAIL_FAILURE = '[Invoices] Update Print Details Failure',

  OVERRIDE_BILL_INVOICE_REQUEST = '[Invoices] Override Bill invoice Request',
  OVERRIDE_BILL_INVOICE_SUCCESS = '[Invoices] Override Bill invoice Success',
  OVERRIDE_BILL_INVOICE_FAILURE = '[Invoices] Override Bill invoice Failure',

  DELETE_OVERRIDE_BILL_INVOICE_REQUEST = '[Invoices] Delete Override Bill invoice Request',
  DELETE_OVERRIDE_BILL_INVOICE_SUCCESS = '[Invoices] Delete Override Bill invoice Success',
  DELETE_OVERRIDE_BILL_INVOICE_FAILURE = '[Invoices] Delete Override Bill invoice Failure',

  LOAD_REQUEST = '[Invoices] Load Request',
  LOAD_FAILURE = '[Invoices] Load Failure',
  LOAD_SUCCESS = '[Invoices] Load Success',

  OPEN_INVOICE_WARNINGS_MODAL_REQUEST = '[Invoices] Open Invoice Warning Modal Request',

  LOAD_BY_STATUS_SDI_REQUEST = '[Invoices] Load By Status Sdi Request',
  LOAD_BY_STATUS_SDI_FAILURE = '[Invoices] Load By Status Sdi Failure',
  LOAD_BY_STATUS_SDI_SUCCESS = '[Invoices] Load By Status Sdi Success',

  LOAD_EMAIL_TEMPLATE_REQUEST = '[Invoices] Load Email Template Request',
  LOAD_EMAIL_TEMPLATE_FAILURE = '[Invoices] Load Email Template Failure',
  LOAD_EMAIL_TEMPLATE_SUCCESS = '[Invoices] Load Email Template Success',

  LOAD_DETAIL_REQUEST = '[Invoices] Load Detail Request',
  LOAD_DETAIL_FAILURE = '[Invoices] Load Detail Failure',
  LOAD_DETAIL_SUCCESS = '[Invoices] Load Detail Success',

  LOAD_DETAIL_SHOW_REQUEST = '[Invoices] Load Detail Show Request',
  LOAD_DETAIL_SHOW_FAILURE = '[Invoices] Load Detail Show Failure',
  LOAD_DETAIL_SHOW_SUCCESS = '[Invoices] Load Detail Show Success',

  LOAD_STATUS_SDI_INFO_REQUEST = '[Invoices] Load Status Sdi Info Request',
  LOAD_STATUS_SDI_INFO_FAILURE = '[Invoices] Load Status Sdi Info Failure',
  LOAD_STATUS_SDI_INFO_SUCCESS = '[Invoices] Load Status Sdi Info Success',

  DELETE_REQUEST = '[Invoices] Delete Request',
  DELETE_FAILURE = '[Invoices] Delete Failure',
  DELETE_SUCCESS = '[Invoices] Delete Success',

  UPDATE_REQUEST = '[Invoices] Update Request',
  UPDATE_FAILURE = '[Invoices] Update Failure',
  UPDATE_SUCCESS = '[Invoices] Update Success',

  CREATE_CREDIT_NOTE_REQUEST = '[Invoices] Create Credit Note Request',
  CREATE_CREDIT_NOTE_FAILURE = '[Invoices] Create Credit Note Failure',
  CREATE_CREDIT_NOTE_SUCCESS = '[Invoices] Create Credit Note Success',

  CREATE_REVERSE_AUTOINVOICE_REQUEST = '[Invoices] Create Reverse Autoinvoice Request',
  CREATE_REVERSE_AUTOINVOICE_FAILURE = '[Invoices] Create Reverse Autoinvoice Failure',
  CREATE_REVERSE_AUTOINVOICE_SUCCESS = '[Invoices] Create Reverse Autoinvoice Success',

  UPDATE_CREDIT_NOTE_REQUEST = '[Invoices] Update Credit Note Request',
  UPDATE_CREDIT_NOTE_FAILURE = '[Invoices] Update Credit Note Failure',
  UPDATE_CREDIT_NOTE_SUCCESS = '[Invoices] Update Credit Note Success',

  SEND_EMAIL_REQUEST = '[Invoices] Send Email Request',
  SEND_EMAIL_FAILURE = '[Invoices] Send Email Failure',
  SEND_EMAIL_SUCCESS = '[Invoices] Send Email Success',

  RESTORE_INVOICE_REQUEST = '[Invoices] Restore Invoice Request',
  RESTORE_INVOICE_FAILURE = '[Invoices] Restore Invoice Failure',
  RESTORE_INVOICE_SUCCESS = '[Invoices] Restore Invoice Success',

  MERGE_INVOICES_REQUEST = '[Invoices] Merge Invoices Request',
  MERGE_INVOICES_FAILURE = '[Invoices] Merge Invoices Failure',
  MERGE_INVOICES_SUCCESS = '[Invoices] Merge Invoices Success',

  LOAD_WIDGETS_REQUEST = '[Invoices] Load Widgets Request',
  LOAD_WIDGETS_FAILURE = '[Invoices] Load Widgets Failure',
  LOAD_WIDGETS_SUCCESS = '[Invoices] Load Widgets Success',

  RESEND_SDI_REQUEST = '[Invoices] Resend Sdi Request',
  RESEND_SDI_FAILURE = '[Invoices] Resend Sdi Failure',
  RESEND_SDI_SUCCESS = '[Invoices] Resend Sdi Success',

  UPDATE_INVOICE_TOTALS = '[Invoices] Update Invoice Totals',

  SET_HOLIDAY_VOUCHER_TOTALS = '[Invoices] Set Holiday Voucher',

  RESET_STATE = '[Invoices] Reset State',

  RESET_DETAIL_STATE = '[Invoices] Reset Detail State',

  EXPORT_FILE_REQUEST = '[Invoices] Export File Request',
  EXPORT_FILE_SUCCESS = '[Invoices] Export File Success',
  EXPORT_FILE_FAILURE = '[Invoices] Export File Failure',
}

export class LoadByStatusSdiRequest implements Action {
  readonly type = ActionTypes.LOAD_BY_STATUS_SDI_REQUEST;
  constructor(
    public payload: {
      params: SearchParams;
    },
  ) {}
}

export class LoadByStatusSdiSuccess implements Action {
  readonly type = ActionTypes.LOAD_BY_STATUS_SDI_SUCCESS;
  constructor(public payload: { invoices?: any; pagination?: IPagination }) {}
}

export class LoadByStatusSdiFailure implements Action {
  readonly type = ActionTypes.LOAD_BY_STATUS_SDI_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class RegisterInvoiceRequest implements Action {
  readonly type = ActionTypes.REGISTER_INVOICE_REQUEST;
  constructor(
    public payload: {
      invoice: InvoiceDetails;
      layout: IInvoiceLayout;
      invoiceCashOut: InvoiceCashOut;
      registerType: InvoiceTypes;
      properties?: number[];
    },
  ) {}
}
export class RegisterInvoiceSuccess implements Action {
  readonly type = ActionTypes.REGISTER_INVOICE_SUCCESS;
}
export class RegisterInvoiceFailure implements Action {
  readonly type = ActionTypes.REGISTER_INVOICE_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class LoadInvoiceNextNumberRequest implements Action {
  readonly type = ActionTypes.LOAD_INVOICE_NEXT_NUMBER_REQUEST;
  constructor(public payload: InvoiceNextNumberRequest) {}
}
export class LoadInvoiceNextNumberSuccess implements Action {
  readonly type = ActionTypes.LOAD_INVOICE_NEXT_NUMBER_SUCCESS;
  constructor(
    public payload: {
      data: any;
      invoice_layout_id: number;
      type?: InvoiceTypes;
    },
  ) {}
}
export class LoadInvoiceNextNumberFailure implements Action {
  readonly type = ActionTypes.LOAD_INVOICE_NEXT_NUMBER_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class DeleteBillOverrideInvoiceRequest implements Action {
  readonly type = ActionTypes.DELETE_OVERRIDE_BILL_INVOICE_REQUEST;
  constructor(
    public payload: {
      reservation_id?: number;
      bill_override_id: number;
      customer_id?: number;
      invoice_id?: number;
    },
  ) {}
}
export class DeleteBillOverrideInvoiceSucccess implements Action {
  readonly type = ActionTypes.DELETE_OVERRIDE_BILL_INVOICE_SUCCESS;
}
export class DeleteBillOverrideInvoiceFailure implements Action {
  readonly type = ActionTypes.DELETE_OVERRIDE_BILL_INVOICE_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class OverrideBillInvoiceRequest implements Action {
  readonly type = ActionTypes.OVERRIDE_BILL_INVOICE_REQUEST;
  constructor(
    public payload: {
      data: IInvoiceOverride;
      edit?: boolean;
      customer_id?: number;
      invoice_id?: number;
      reservationsBillsOverrideIdMerge?: number[];
      noReload?: boolean;
    },
  ) {}
}
export class OverrideBillInvoiceSuccess implements Action {
  readonly type = ActionTypes.OVERRIDE_BILL_INVOICE_SUCCESS;
  constructor(public payload: { noReload?: boolean }) {}
}
export class OverrideBillInvoiceFailure implements Action {
  readonly type = ActionTypes.OVERRIDE_BILL_INVOICE_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: SearchParams) {}
}
export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: Invoice[]; pagination: IPagination }) {}
}
export class LoadEmailTemplateRequestAction implements Action {
  readonly type = ActionTypes.LOAD_EMAIL_TEMPLATE_REQUEST;
  constructor(
    public payload: {
      invoice_id: number;
    },
  ) {}
}
export class LoadEmailTemplateFailureAction implements Action {
  readonly type = ActionTypes.LOAD_EMAIL_TEMPLATE_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadEmailTemplateSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_EMAIL_TEMPLATE_SUCCESS;
  constructor(public payload: { data: any }) {}
}

export class LoadDetailsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_REQUEST;
  constructor(public payload: { invoice_id: number }) {}
}
export class LoadDetailsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadDetailsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_SUCCESS;
  constructor(public payload: { items: InvoiceDetails }) {}
}

export class LoadDetailsShowRequestAction implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_SHOW_REQUEST;
  constructor(public payload: { invoice_id: number }) {}
}
export class LoadDetailsShowFailureAction implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_SHOW_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadDetailsShowSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_SHOW_SUCCESS;
  constructor(public payload: { items: any }) {}
}

export class LoadStatusSdiInfoRequestAction implements Action {
  readonly type = ActionTypes.LOAD_STATUS_SDI_INFO_REQUEST;
}
export class LoadStatusSdiInfoFailureAction implements Action {
  readonly type = ActionTypes.LOAD_STATUS_SDI_INFO_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadStatusSdiInfoSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_STATUS_SDI_INFO_SUCCESS;
  constructor(public payload: { items: SdiInfo[] }) {}
}

export class CreateCreditNoteRequestAction implements Action {
  readonly type = ActionTypes.CREATE_CREDIT_NOTE_REQUEST;
  constructor(
    public payload: { invoice: InvoiceDetails; layout: IInvoiceLayout },
  ) {}
}
export class CreateCreditNoteFailureAction implements Action {
  readonly type = ActionTypes.CREATE_CREDIT_NOTE_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class CreateCreditNoteSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_CREDIT_NOTE_SUCCESS;
  constructor(public payload: { creditNote: InvoiceDetails }) {}
}

export class CreateReverseAutoinvoiceRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REVERSE_AUTOINVOICE_REQUEST;
  constructor(
    public payload: { invoice: InvoiceDetails; layout: IInvoiceLayout },
  ) {}
}
export class CreateReverseAutoinvoiceFailureAction implements Action {
  readonly type = ActionTypes.CREATE_REVERSE_AUTOINVOICE_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class CreateReverseAutoinvoiceSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_REVERSE_AUTOINVOICE_SUCCESS;
  constructor(public payload: { reverseAutoinvoice: InvoiceDetails }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(
    public payload: { invoiceId: number; forceOperation?: boolean },
  ) {}
}
export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { invoiceId: number }) {}
}

export class OpenWarningsModalRequestAction implements Action {
  readonly type = ActionTypes.OPEN_INVOICE_WARNINGS_MODAL_REQUEST;
  constructor(
    public payload: {
      message: string;
      actionToDispatch: Action;
      discardAction: Action;
    },
  ) {}
}

export class RestoreInvoiceRequestAction implements Action {
  readonly type = ActionTypes.RESTORE_INVOICE_REQUEST;
  constructor(public payload: { invoiceId: number }) {}
}
export class RestoreInvoiceFailureAction implements Action {
  readonly type = ActionTypes.RESTORE_INVOICE_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class RestoreInvoiceSuccessAction implements Action {
  readonly type = ActionTypes.RESTORE_INVOICE_SUCCESS;
  constructor(public payload: { restoredId: number }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      request: any;
      activeChargeListData?: ActiveChargeUpdateListItemRequest;
      noReload?: boolean;
      dataRegisterInvoice?: RegisterInvoice;
      selectedFiscalPrinter?: FiscalPrinter;
      creditCardDataSend?: any;
      hooks?: Hooks;
      force_operation?: boolean;
    },
  ) {}
}
export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string; hooks?: Hooks }) {}
}
export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(
    public payload: {
      invoice: any;
      activeChargeListData?: ActiveChargeUpdateListItemRequest;
      noReload?: boolean;
    },
  ) {}
}

export class SendEmailRequestAction implements Action {
  readonly type = ActionTypes.SEND_EMAIL_REQUEST;
  constructor(public payload: { request: SendMailInvoiceRequest }) {}
}
export class SendEmailFailureAction implements Action {
  readonly type = ActionTypes.SEND_EMAIL_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class SendEmailSuccessAction implements Action {
  readonly type = ActionTypes.SEND_EMAIL_SUCCESS;
}

export class MergeInvoicesRequestAction implements Action {
  readonly type = ActionTypes.MERGE_INVOICES_REQUEST;
  constructor(
    public payload: {
      invoice_id_destination: number;
      invoice_id_source: number[];
      reservation_accommodation_id?: number;
      hooks?: Hooks;
    },
  ) {}
}
export class MergeInvoicesFailureAction implements Action {
  readonly type = ActionTypes.MERGE_INVOICES_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class MergeInvoicesSuccessAction implements Action {
  readonly type = ActionTypes.MERGE_INVOICES_SUCCESS;
}
export class ResendSdiRequestAction implements Action {
  readonly type = ActionTypes.RESEND_SDI_REQUEST;
  constructor(public payload: ResendSdi) {}
}
export class ResendSdiFailureAction implements Action {
  readonly type = ActionTypes.RESEND_SDI_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class ResendSdiSuccessAction implements Action {
  readonly type = ActionTypes.RESEND_SDI_SUCCESS;
}

export class LoadWidgetsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_WIDGETS_REQUEST;
  constructor(
    public payload: {
      filters: SearchParams;
      widgetType: InvoiceWidgetTypes;
      periods: InvoiceWidgetsPeriod[];
    },
  ) {}
}
export class LoadWidgetsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_WIDGETS_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadWidgetsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_WIDGETS_SUCCESS;
  constructor(public payload: { widgets: InvoiceWidgets }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export class ResetDetailSuccessAction implements Action {
  readonly type = ActionTypes.RESET_DETAIL_STATE;
}

export class UpdateInvoiceTotalsAction implements Action {
  readonly type = ActionTypes.UPDATE_INVOICE_TOTALS;
  constructor(public payload: { totals: Partial<InvoiceTotals> }) {}
}

export class SetHolidayVoucherAction implements Action {
  readonly type = ActionTypes.SET_HOLIDAY_VOUCHER_TOTALS;
  constructor(public payload: { usingHolidayVoucher: boolean }) {}
}

export class ExportFileRequest implements Action {
  readonly type = ActionTypes.EXPORT_FILE_REQUEST;
  constructor(public payload: { params: InvoiceListExportFileRequest }) {}
}

export class ExportFileSuccess implements Action {
  readonly type = ActionTypes.EXPORT_FILE_SUCCESS;
  constructor(public payload: { exportFileId: number }) {}
}
export class ExportFileFailure implements Action {
  readonly type = ActionTypes.EXPORT_FILE_FAILURE;
  constructor(public payload: { error: any }) {}
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadEmailTemplateRequestAction
  | LoadEmailTemplateFailureAction
  | LoadEmailTemplateSuccessAction
  | LoadStatusSdiInfoRequestAction
  | LoadStatusSdiInfoFailureAction
  | LoadStatusSdiInfoSuccessAction
  | LoadDetailsShowRequestAction
  | LoadDetailsShowFailureAction
  | LoadDetailsShowSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | SendEmailRequestAction
  | SendEmailSuccessAction
  | SendEmailFailureAction
  | CreateCreditNoteRequestAction
  | CreateCreditNoteSuccessAction
  | CreateCreditNoteFailureAction
  | ResetSuccessAction
  | ResetDetailSuccessAction
  | LoadDetailsSuccessAction
  | LoadDetailsRequestAction
  | LoadDetailsFailureAction
  | OverrideBillInvoiceRequest
  | OverrideBillInvoiceSuccess
  | OverrideBillInvoiceFailure
  | DeleteBillOverrideInvoiceRequest
  | DeleteBillOverrideInvoiceSucccess
  | DeleteBillOverrideInvoiceFailure
  | LoadInvoiceNextNumberRequest
  | LoadInvoiceNextNumberSuccess
  | LoadInvoiceNextNumberFailure
  | RegisterInvoiceRequest
  | RegisterInvoiceSuccess
  | RegisterInvoiceFailure
  | MergeInvoicesRequestAction
  | MergeInvoicesSuccessAction
  | MergeInvoicesFailureAction
  | LoadWidgetsRequestAction
  | LoadWidgetsSuccessAction
  | LoadWidgetsFailureAction
  | UpdateInvoiceTotalsAction
  | SetHolidayVoucherAction
  | LoadByStatusSdiRequest
  | LoadByStatusSdiSuccess
  | LoadByStatusSdiFailure
  | RestoreInvoiceRequestAction
  | RestoreInvoiceSuccessAction
  | RestoreInvoiceFailureAction
  | ExportFileRequest
  | ExportFileSuccess
  | ExportFileFailure;
