import {
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormBuilder,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzTableModule } from 'ng-zorro-antd/table';
import {
  RefundPaymentsCreditNote,
  RefundPaymentsCreditNoteForm,
  TypedSimpleChanges,
} from '@app/models';
import {
  NgTruncatePipeModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { Subscription } from 'rxjs';
import { sumBy } from 'lodash';
import { CurrencyFormatComponent } from '@app/ui';
import { FormatDateModule } from '@app/pipes';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAlertModule } from 'ng-zorro-antd/alert';

@Component({
  selector: 'by-credit-note-payments-table',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NzTableModule,
    NgUpperFirstPipeModule,
    TranslateModule,
    NzInputNumberModule,
    CurrencyFormatComponent,
    FormatDateModule,
    NzGridModule,
    NzToolTipModule,
    NzAlertModule,
    NgTruncatePipeModule,
  ],
  templateUrl: './credit-note-payments-table.component.html',
  styleUrl: './credit-note-payments-table.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreditNotePaymentsTableComponent),
      multi: true,
    },
  ],
})
export class CreditNotePaymentsTableComponent
  implements ControlValueAccessor, OnDestroy, OnInit, OnChanges
{
  @Input({ required: true }) maxAmountToCancel: number;

  @Input({ required: true }) minAdvanceInvoiceAmountToSet: number;

  @Input({ required: true }) isOnlyOneAdvanceAttached: boolean;

  @Output() resetRefundsByAmount = new EventEmitter<number>();

  private fb = inject(FormBuilder);

  onChange: (value: Partial<RefundPaymentsCreditNote>[]) => void = () => {};

  onTouched: () => void = () => {};

  refundPayments = this.fb.array<RefundPaymentsCreditNoteForm>([]);

  maxAmountsMap: Record<number, number> = {};

  balanceToCancel = 0;

  remainingAmountAvailable = 0;

  private subs = new Subscription();

  ngOnChanges(
    changes: TypedSimpleChanges<{ maxAmountToCancel: number }>,
  ): void {
    if (changes.maxAmountToCancel) {
      this.remainingAmountAvailable =
        changes.maxAmountToCancel.currentValue - this.balanceToCancel;

      this.updateMaxAmounts();
    }
  }

  ngOnInit(): void {
    this.subs.add(
      this.refundPayments.valueChanges.subscribe((refunds) => {
        this.balanceToCancel = sumBy(refunds, 'currentAmount');

        this.remainingAmountAvailable =
          this.maxAmountToCancel - this.balanceToCancel;

        this.updateMaxAmounts();

        this.onChange(refunds);
      }),
    );
  }

  writeValue(newRefundPayments: RefundPaymentsCreditNote[]): void {
    this.refundPayments.clear({ emitEvent: false });

    if (newRefundPayments && newRefundPayments.length) {
      newRefundPayments.forEach((refundPayments, index) => {
        this.refundPayments.push(this.fb.group({ ...refundPayments }), {
          emitEvent: false,
        });

        this.refundPayments.updateValueAndValidity({
          emitEvent: index === newRefundPayments.length - 1,
        });
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateMaxAmounts(): void {
    this.refundPayments.controls.forEach((group, index) => {
      const { currentAmount, maxAmount } = group?.value;

      this.maxAmountsMap[index] = Math.min(
        this.maxAmountToCancel,
        this.remainingAmountAvailable + currentAmount,
        maxAmount ?? Infinity,
      );
    });
  }

  setMaxAmount(index: number): void {
    if (this.isRemainAmountNotAvailable) {
      return;
    }

    const maxAvailableAmount =
      this.refundPayments.controls[index].value.maxAmount;

    const currentAmount =
      this.refundPayments.controls[index].value.currentAmount;

    if (
      maxAvailableAmount > 0 &&
      this.remainingAmountAvailable + currentAmount >= maxAvailableAmount
    ) {
      this.setSingleAmount(index, maxAvailableAmount);
      return;
    }

    this.setSingleAmount(index, this.remainingAmountAvailable + currentAmount);
  }

  resetSingleAmount(index: number) {
    this.setSingleAmount(index, 0);
  }

  resetSingleAmountAdvanceInvoice(index: number) {
    const currentRefund = this.refundPayments.controls[index].value;

    const amountSetInOtherAdvanceInvoice =
      this.totalSelectedAdvanceInvoice - currentRefund.currentAmount;

    if (amountSetInOtherAdvanceInvoice >= this.minAdvanceInvoiceAmountToSet) {
      this.setSingleAmount(index, 0);
      return;
    }

    this.setSingleAmount(
      index,
      this.minAdvanceInvoiceAmountToSet - amountSetInOtherAdvanceInvoice,
    );
  }

  setSingleAmount(index: number, value: number) {
    this.refundPayments.controls[index].controls.currentAmount.patchValue(
      value,
    );
  }

  get isBalanceToCancelNotTotalSelected() {
    return this.balanceToCancel !== this.maxAmountToCancel;
  }

  get isRemainAmountNotAvailable() {
    return this.remainingAmountAvailable === 0;
  }

  get totalSelectedAdvanceInvoice() {
    return sumBy(this.refundPayments.value, (payment) =>
      payment.advanceInvoiceId ? payment.currentAmount : 0,
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
