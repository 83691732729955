import { Injectable } from '@angular/core';
import { ICoreState } from '@app/core/+state/core.reducer';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { AddonsService } from '@app/services/addons.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import {
  catchError,
  map,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as featureActions from './actions';

@Injectable()
export class AddonsStoreEffects {
  constructor(
    private dataService: AddonsService,
    private actions$: Actions,
    private store: Store<{ core: ICoreState }>,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  loadAccommodationAddonsRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadAccommodationAddonsRequestAction>(
        featureActions.ActionTypes.LOAD_ACCOMMODATION_ADDONS_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadAccommodationAddonsRequestAction,
          { core: ICoreState },
        ]) => {
          const {
            accommodationId,
            adults_number,
            property_children_ranges,
            reference_date,
          } = action.payload;

          return this.dataService
            .loadAccommodationAddons({
              accommodationId,
              adults_number,
              property_children_ranges,
              reference_date,
            })
            .pipe(
              map(
                ({ data }: any) =>
                  new featureActions.LoadAccommodationAddonsSuccessAction({
                    items: data,
                  }),
              ),
              catchError((error) =>
                of(
                  new featureActions.LoadAccommodationAddonsFailureAction({
                    error,
                  }),
                ),
              ),
            );
        },
      ),
    ),
  );

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadRequestAction,
          { core: ICoreState },
        ]) => {
          let propertiesIds = action.payload.propertiesIds;
          if (!propertiesIds) {
            propertiesIds = store.core.allProperties.map(({ id }) => id);
          }
          return this.dataService.load(propertiesIds).pipe(
            map(
              ({ data }: any) =>
                new featureActions.LoadSuccessAction({
                  items: data,
                }),
            ),
            catchError((error) =>
              of(new featureActions.LoadFailureAction({ error })),
            ),
          );
        },
      ),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.CreateRequestAction,
          { core: ICoreState },
        ]) => {
          let propertiesIds = action.payload.request.propertiesIds;
          if (!propertiesIds) {
            propertiesIds = store.core.allProperties.map(({ id }) => id);
          }
          const request = {
            ...action.payload.request,
            propertyId: propertiesIds,
          };
          return this.dataService.create(request).pipe(
            map(
              ({ data }: any) =>
                new featureActions.CreateSuccessAction({
                  item: data[0],
                }),
            ),
            catchError((error) =>
              of(new featureActions.CreateFailureAction({ error })),
            ),
          );
        },
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.DeleteRequestAction,
          { core: ICoreState },
        ]) => {
          const { addonId } = action.payload;
          return this.dataService.delete(addonId).pipe(
            map(() => {
              this.notifications.deleteSuccess('addon');
              return new featureActions.DeleteSuccessAction({
                addonId,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.UpdateRequestAction,
          { core: ICoreState },
        ]) => {
          let propertiesIds = action.payload.request.propertiesIds;
          if (!propertiesIds) {
            propertiesIds = store.core.allProperties.map(({ id }) => id);
          }
          const request = {
            ...action.payload.request,
            propertyId: propertiesIds,
          };
          return this.dataService.update(request).pipe(
            map(
              (response: IResponseSuccess) =>
                new featureActions.UpdateSuccessAction({
                  addon: response.data[0],
                }),
            ),
            catchError((error) =>
              of(new featureActions.UpdateFailureAction({ error })),
            ),
          );
        },
      ),
    ),
  );
}
