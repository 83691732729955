<div
  nz-form
  [formGroup]="form"
  class="by-flex by-flex-direction--column by-gap-20"
>
  @if (isTotalStep) {
    <div nz-row>
      <div nz-col [nzSpan]="14">
        {{ 'credit_note_typology' | translate | upperfirst }}
      </div>

      <div nz-col [nzSpan]="10">
        <nz-radio-group
          formControlName="creditNoteType"
          nzButtonStyle="solid"
          class="full-width-radio-group"
        >
          <label
            nz-radio-button
            nzValue="partial"
            class="full-width-button custom-radio-remove-focus-within"
          >
            {{ 'partial' | translate | upperfirst }}
          </label>
          <label
            nz-tooltip
            [nzTooltipTitle]="
              (isAdvanceInvoice
                ? 'existing_advace_credit_note_message'
                : 'info_not_possible_total_credit_note'
              )
                | translate
                | upperfirst
            "
            [nzTooltipTrigger]="
              hasInvoiceLinkedDocuments || hasBalanceInvoices ? 'hover' : null
            "
            nz-radio-button
            nzValue="total"
            class="full-width-button custom-radio-remove-focus-within"
            [nzDisabled]="hasInvoiceLinkedDocuments || hasBalanceInvoices"
          >
            {{ 'total' | translate | upperfirst }}
          </label>
        </nz-radio-group>
      </div>
    </div>

    @if (isCreditNotePartial) {
      <div nz-row>
        <div nz-col [nzSpan]="14">
          {{ 'question_what_you_want_cancel' | translate | upperfirst }}
          <i
            class="far fa-info-circle pointer color--orange"
            nz-tooltip
            [nzTooltipTitle]="
              'info_question_credit_note_what_cancel' | translate | upperfirst
            "
          ></i>
        </div>

        <div nz-col [nzSpan]="10">
          <nz-radio-group
            formControlName="reversalType"
            nzButtonStyle="solid"
            class="full-width-radio-group"
          >
            <label
              nz-radio-button
              nzValue="import"
              [nzDisabled]="isOnlyCityTaxRefund"
              class="full-width-button"
            >
              {{ 'import' | translate | upperfirst }}
            </label>
            <label
              nz-radio-button
              nzValue="bills"
              class="full-width-button"
              nz-tooltip
              [nzTooltipTitle]="
                'info_not_possible_single_bills_advance_invoice'
                  | translate
                  | upperfirst
              "
              [nzTooltipTrigger]="isAdvanceInvoice ? 'hover' : null"
              [nzDisabled]="isAdvanceInvoice"
            >
              {{ 'single_bills' | translate | upperfirst }}
            </label>
          </nz-radio-group>
        </div>
      </div>
    }

    @if (isOnlyCityTaxRefund && isCreditNotePartial) {
      <nz-alert
        nzType="warning"
        [nzMessage]="'warning_credit_note_only_tax' | translate | upperfirst"
        nzShowIcon
      ></nz-alert>
    }
  }

  @if (isCreditNotePartial && !isReversalTypeBills) {
    <div class="by-w-100">
      <by-credit-note-register-slider
        [maxValue]="invoice.maxRefundable"
        formControlName="totalValue"
        [invoice]="invoice"
      ></by-credit-note-register-slider>
    </div>
  }

  @if (!isTotalStep) {
    <by-credit-note-register-bills-table
      [invoice]="invoice"
      [mediasInvoiceLayouts]="mediasInvoiceLayouts"
      (generateRefundsPayments)="refillRefundsWithVirtualStamp($event)"
    ></by-credit-note-register-bills-table>
  }

  @if (
    !(this.isTotalStep && this.isCreditNotePartial && this.isReversalTypeBills)
  ) {
    @if (isCreditNotePartial && isInvoiceFromReservation && !isAdvanceInvoice) {
      <div nz-row class="by-pb-10">
        <div nz-col [nzXs]="14">
          <div class="by-pt-5">
            <span
              >{{ 'question_charge_amount_aligned' | translate | upperfirst }}
            </span>

            <i
              class="far fa-info-circle pointer color--orange"
              nz-tooltip
              [nzTooltipTitle]="toolTipTitleAmountAligned"
            ></i>
            <ng-template #toolTipTitleAmountAligned>
              <div
                [innerHtml]="
                  'info_charge_amount_aligned' | translate | upperfirst
                "
              ></div>
            </ng-template>
          </div>
        </div>
        <div nz-col [nzXs]="10" class="by-flex by-justify-content--flex-end">
          <nz-radio-group
            formControlName="chargeAmountAligned"
            nzButtonStyle="solid"
            class="radio-group-question"
          >
            <label
              nz-radio-button
              [nzValue]="1"
              class="radio-button-question custom-radio-remove-focus-within"
              >{{ 'yes' | translate | uppercase }}</label
            >
            <label
              nz-radio-button
              [nzValue]="0"
              class="radio-button-question custom-radio-remove-focus-within"
              >{{ 'no' | translate | uppercase }}</label
            >
          </nz-radio-group>
        </div>
      </div>
    }

    @if (isCreditNotePartial && !isInvoiceFromReservation) {
      <nz-alert
        class="by-pb-10"
        nzType="warning"
        [nzMessage]="
          'info_partial_credit_note_charge_case' | translate | upperfirst
        "
      >
      </nz-alert>
    }

    @if (creditNoteTypeValue) {
      <by-credit-note-virtual-stamp-register
        formControlName="virtualStamp"
      ></by-credit-note-virtual-stamp-register>

      @if (!isInvoiceTotalSuspended) {
        <div nz-row>
          <div nz-col [nzXs]="14">
            <div class="by-pt-5">
              <span
                >{{
                  'question_you_want_to_issue_the_refund_same_time'
                    | translate
                    | upperfirst
                }}
              </span>
              <i
                class="far fa-info-circle pointer color--orange"
                nz-tooltip
                [nzTooltipTitle]="
                  'info_question_want_issue_refund_same_time'
                    | translate
                    | upperfirst
                "
              ></i>
            </div>
          </div>
          <div nz-col [nzXs]="10" class="by-flex by-justify-content--flex-end">
            <nz-radio-group
              formControlName="refundSameTime"
              nzButtonStyle="solid"
              class="radio-group-question"
            >
              <label
                nz-radio-button
                [nzValue]="1"
                class="radio-button-question custom-radio-remove-focus-within"
                >{{ 'yes' | translate | uppercase }}</label
              >
              <label
                nz-radio-button
                [nzValue]="0"
                class="radio-button-question custom-radio-remove-focus-within"
                nz-tooltip
                [nzTooltipTitle]="
                  'credit_note_tooltip_attached_advance_refund_same_time'
                    | translate
                    | upperfirst
                "
                [nzTooltipTrigger]="isAdvanceInvoiceAttached ? 'hover' : null"
                [nzDisabled]="isAdvanceInvoiceAttached"
                >{{ 'no' | translate | uppercase }}</label
              >
            </nz-radio-group>
          </div>
        </div>
      }
    }

    @if (showRefundSameTimeQuestion) {
      <div class="by-pt-20">
        <nz-alert
          nzType="warning"
          [nzMessage]="titleWarning"
          nzShowIcon
          class="by-pb-5"
        ></nz-alert>
        <ng-template #titleWarning>
          <div
            [innerHtml]="'warning_pos_credit_note' | translate | upperfirst"
          ></div>
        </ng-template>

        <div class="by-pt-10">
          <by-page-header
            [titleTemplate]="title"
            [byStyle]="{
              'padding-top': 0,
              'margin-bottom': '15px'
            }"
          ></by-page-header>
        </div>

        <ng-template #title>
          <span>
            {{ 'title_split_import_on_payments' | translate | upperfirst }}
            <i
              class="far fa-info-circle pointer color--orange by-fs-12"
              nz-tooltip
              [nzTooltipTitle]="
                'title_split_import_on_payments_info_tooltip'
                  | translate
                  | upperfirst
              "
            ></i>
          </span>
        </ng-template>

        <by-credit-note-payments-table
          formControlName="refundPayments"
          [maxAmountToCancel]="amountPayments"
          [minAdvanceInvoiceAmountToSet]="minAdvanceInvoiceAmountToSet"
          [isOnlyOneAdvanceAttached]="isOnlyOneAdvanceAttached"
          (resetRefundsByAmount)="refillRefundsWithVirtualStamp($event)"
        ></by-credit-note-payments-table>
      </div>
    }
  }
</div>
