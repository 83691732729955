import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { combineLatest } from 'rxjs';
import { of } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { selectAllProperties } from '../../core/+state/core.reducer';
import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { DateFormatterService } from '../../core/services/date-formatter.service';
import {
  CompanyLookup,
  Customer,
  CustomerLookup,
  CustomersLookupMethods,
  CustomersLookupOption,
} from '../../models';
import { SearchParams } from '../../models/objects/search-params';
import { AppStore } from '../../models/types/app-store';
import { CompaniesService, CustomersService } from '../../services';

const CUSTOMER_ICON = 'fas fa-user-alt color--azure';
const COMPANY_ICON = 'fas fa-building color--green';

@Component({
  selector: 'by-customers-lookup',
  templateUrl: './customers-lookup.component.html',
  styleUrls: ['./customers-lookup.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomersLookupComponent,
      multi: true,
    },
  ],
})
export class CustomersLookupComponent
  implements OnInit, OnDestroy, OnChanges, ControlValueAccessor
{
  @Input()
  searchIn: Array<'customers' | 'companies'> = ['customers'];

  @Input()
  optionClassFn: (customerId: number) => string;

  @Input()
  optionFilterFn: (customer: CustomersLookupOption) => boolean;

  @Input()
  optionDisabledFn: (customer: CustomersLookupOption) => boolean;

  @Input()
  allowClear = false;

  @Input()
  optionsSortFn: (a: CustomersLookupOption, b: CustomersLookupOption) => number;

  @Input()
  placeHolder = 'search_something';

  @Input()
  size: 'large' | 'small' | 'default' = 'default';

  @Input() optionTpl: TemplateRef<any>;

  @Input() propertyIDS: number[];

  @Input()
  isDisabled = false;

  @Input()
  searchParams: SearchParams;

  @Output()
  customerSelected = new EventEmitter<CustomersLookupOption>();

  customersOptions: CustomersLookupOption[] = [];

  control = this.formBuilder.control(null);

  protected subs = new SubSink();

  protected defaultpropertyIDS: number[];

  protected searchSubscription: Subscription;

  readonly COMPANY_ICON = COMPANY_ICON;

  constructor(
    protected store: Store<AppStore>,
    protected customersService: CustomersService,
    protected companiesService: CompaniesService,
    protected dateFormatter: DateFormatterService,
    protected formBuilder: UntypedFormBuilder,
    protected modalService: NzModalService,
    protected translate: TranslateService,
    protected cdr: ChangeDetectorRef,
  ) {}

  onTouched: any = () => {};

  ngOnInit() {
    this.subs.add(
      this.store.pipe(select(selectAllProperties)).subscribe((properties) => {
        this.defaultpropertyIDS = (properties || []).map(({ id }) => id);
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.searchSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisabled) {
      this.setDisabledState(this.isDisabled);
    }
  }

  writeValue(value: number | number) {
    if (value) {
      this.addPreselectedCustomerInOptions(+value);
    }

    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any) {
    this.subs.add(
      this.control.valueChanges.subscribe((customerId) => {
        fn(customerId);
        this.emitSelectedCustomer(customerId);
      }),
    );
  }

  emitSelectedCustomer(customerId: number) {
    const customer = this.customersOptions.find(({ id }) => id === customerId);

    const notes = customer?.customer?.note || customer?.company?.internal_notes;

    if (notes) {
      this.modalService.info({
        nzTitle: upperFirst(this.translate.instant('selected_customer_notes')),
        nzContent: notes,
      });
    }

    this.customerSelected.emit(customer);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    const fn = disabled ? 'disable' : 'enable';
    this.control[fn]({ emitEvent: false });
  }

  onSearchCustomers(value: string, searchBy: CustomersLookupMethods = 'value') {
    this.customersOptions = [];

    if (!value || !value.trim || !value.trim()) {
      return;
    }

    let customers$: Observable<CustomerLookup[]> = of([]);
    if (this.searchIn.includes('customers')) {
      customers$ = this.getCustomers(value, searchBy);
    }

    let companies$: Observable<CompanyLookup[]> = of([]);
    if (this.searchIn.includes('companies')) {
      companies$ = this.getCompanies(value);
    }

    this.searchSubscription?.unsubscribe();

    this.searchSubscription = combineLatest([customers$, companies$]).subscribe(
      ([customers, companies]) => {
        this.customersOptions = [
          ...(customers || []).map((customer) => {
            const customerInfos = [];

            if (customer.birthday_date) {
              customerInfos.push(
                this.dateFormatter.toUiFormat(new Date(customer.birthday_date)),
              );
            }

            if (customer.detail?.email) {
              customerInfos.push(customer.detail?.email);
            }

            const collectedCustomerInfos = customerInfos.length
              ? `(${customerInfos.join(' - ')})`
              : '';

            const full_name = (
              searchBy === 'surname'
                ? [customer.surname, customer.name]
                : [customer.name, customer.surname]
            )
              .filter((v) => v)
              .join(' ');

            return {
              id: customer.id,
              name: `${full_name} ${collectedCustomerInfos}`,
              icon: CUSTOMER_ICON,
              class: this.optionClassFn ? this.optionClassFn(customer.id) : '',
              customer: {
                ...customer,
                full_name,
              },
            };
          }),
          ...(companies || []).map((company) => ({
            id: this.getCompanyId(company),
            name: `${company.name} ${
              company.vat_code ? `(${company.vat_code})` : ''
            }`,
            icon: COMPANY_ICON,
            company: { ...company, surname: '' },
          })),
        ]
          .sort(this.optionsSortFn ? this.optionsSortFn : (a, b) => b.id - a.id)
          .filter((option) =>
            this.optionFilterFn ? this.optionFilterFn(option) : true,
          )
          .map((option) => ({
            ...option,
            disabled: this.optionDisabledFn
              ? this.optionDisabledFn(option)
              : false,
          }));

        this.cdr.detectChanges();
      },
    );
  }

  resetOptions() {
    this.customersOptions = [];
  }

  protected getCompanyId(company: CompanyLookup) {
    return company.customer_id;
  }

  protected getCustomers(
    value: string,
    searchBy: CustomersLookupMethods = 'value',
  ): Observable<CustomerLookup[]> {
    return this.customersService
      .searchLookup(this.allPropertiesIds, value, this.searchParams, searchBy)
      .pipe(map(({ data }: IResponseSuccess) => data));
  }

  protected getCompanies(value: string): Observable<CompanyLookup[]> {
    return this.companiesService
      .search(this.allPropertiesIds, value, this.searchParams)
      .pipe(map(({ data }: IResponseSuccess) => data));
  }

  protected addPreselectedCustomerInOptions(customerId: number) {
    const customerExistInOptions = !!this.customersOptions.find(
      ({ id }) => +id === +customerId,
    );

    if (customerExistInOptions) {
      return;
    }

    this.getOptionFromId(customerId)
      .pipe(take(1))
      .subscribe((option) => {
        this.customersOptions = [option];
      });
  }

  protected getOptionFromId(
    customerId: number,
  ): Observable<CustomersLookupOption> {
    return this.customersService
      .loadDetails(customerId, this.allPropertiesIds)
      .pipe(
        map(({ data: customers }: IResponseSuccess<Customer[]>) => {
          const customer = customers[0];
          const isCompany = customer.category_id === 4;

          let name = [customer.name, customer.surname]
            .filter((v) => v)
            .join(' ');

          if (isCompany) {
            name = customer.surname || customer.name;

            if (customer.company) {
              this.customerSelected.emit({
                name,
                id: customer.id,
                company: customer.company,
              });
            }
          }

          return {
            id: customer.id,
            name,
            icon: isCompany ? COMPANY_ICON : CUSTOMER_ICON,
          };
        }),
      );
  }

  protected get allPropertiesIds(): number[] {
    return this.propertyIDS || this.defaultpropertyIDS;
  }
}
