import { MobileAppEventType } from '@app/models';

export function emitMobileAppEvent(
  event: MobileAppEventType,
  payload?: string,
): boolean {
  if (!window['ReactNativeWebView']) {
    return false;
  }

  let message = `${event + '@b$y@'}`;

  if (payload) {
    message = `${message}${payload}`;
  }

  window['ReactNativeWebView'].postMessage(btoa(message));

  return true;
}
