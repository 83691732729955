<div nz-form [formGroup]="form">
  <div nz-row class="by-mb-10">
    <div nz-col [nzXs]="14">
      <div class="by-pt-5">
        <span
          >{{ 'question_want_apply_virtual_stamp' | translate | upperfirst }}
        </span>
      </div>
    </div>
    <div nz-col [nzXs]="10" class="by-flex by-justify-content--flex-end">
      <nz-radio-group
        formControlName="createStamp"
        nzButtonStyle="solid"
        class="radio-group-question"
      >
        <label
          nz-radio-button
          [nzValue]="1"
          class="radio-button-question custom-radio-remove-focus-within"
          >{{ 'yes' | translate | uppercase }}</label
        >
        <label
          nz-radio-button
          [nzValue]="0"
          class="radio-button-question custom-radio-remove-focus-within"
          >{{ 'no' | translate | uppercase }}</label
        >
      </nz-radio-group>
    </div>
  </div>

  @if (createStampEnabled) {
    <nz-form-item class="box-padding-horizontal">
      <nz-form-label [nzSpan]="20">
        {{ 'enter_amount' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control [nzSpan]="4">
        <nz-input-number
          class="by-w-100"
          byDecimalOnly
          [nzMin]="0"
          [nzStep]="1"
          formControlName="stampAmount"
          [nzFormatter]="formatterCurrency"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="box-padding-horizontal by-pt-15 by-pb-20">
      <nz-form-label [nzSpan]="20">
        {{ 'virtual_stamp_on_customer' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control [nzSpan]="4">
        <nz-select class="by-w-100" formControlName="chargeToCustomer">
          <nz-option
            [nzValue]="1"
            [nzLabel]="'yes' | translate | upperfirst"
          ></nz-option>
          <nz-option
            [nzValue]="0"
            [nzLabel]="'no' | translate | upperfirst"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  }
</div>
