export const getPlatform = (): 'android' | 'ios' | 'unknown' => {
  const userAgent = navigator?.userAgent?.toLowerCase();
  if (userAgent?.includes('android')) {
    return 'android';
  } else if (
    userAgent?.includes('iphone') ||
    userAgent?.includes('ipad') ||
    userAgent?.includes('ios')
  ) {
    return 'ios';
  } else {
    return 'unknown';
  }
};
