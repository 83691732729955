import { Action } from '@ngrx/store';

import { IAddonRequestRoot, IAddonRoot } from './../../models/objects/addons';

export enum ActionTypes {
  LOAD_REQUEST = '[Addon] Load Request',
  LOAD_FAILURE = '[Addon] Load Failure',
  LOAD_SUCCESS = '[Addon] Load Success',

  LOAD_ACCOMMODATION_ADDONS_REQUEST = '[Addon] Load Accommodation Addons Request',
  LOAD_ACCOMMODATION_ADDONS_FAILURE = '[Addon] Load Accommodation Addons Failure',
  LOAD_ACCOMMODATION_ADDONS_SUCCESS = '[Addon] Load Accommodation Addons Success',

  CREATE_REQUEST = '[Addon] Create Request',
  CREATE_FAILURE = '[Addon] Create Failure',
  CREATE_SUCCESS = '[Addon] Create Success',

  DELETE_REQUEST = '[Addon] Delete Request',
  DELETE_FAILURE = '[Addon] Delete Failure',
  DELETE_SUCCESS = '[Addon] Delete Success',

  UPDATE_REQUEST = '[Addon] Update Request',
  UPDATE_FAILURE = '[Addon] Update Failure',
  UPDATE_SUCCESS = '[Addon] Update Success',

  LOAD_CHILDREN_RANGES_REQUEST = '[Addon] lo Request',
  LOAD_CHILDREN_RANGES_FAILURE = '[Addon] lo Failure',
  LOAD_CHILDREN_RANGES_SUCCESS = '[Addon] lo Success',

  RESET_STATE = '[Addon] Reset State',
}

export class LoadAccommodationAddonsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_ACCOMMODATION_ADDONS_REQUEST;
  constructor(
    public payload: {
      accommodationId?: number;
      adults_number?: number;
      property_children_ranges?: number[];
      reference_date?: string;
    },
  ) {}
}

export class LoadAccommodationAddonsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ACCOMMODATION_ADDONS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadAccommodationAddonsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ACCOMMODATION_ADDONS_SUCCESS;
  constructor(public payload: { items: IAddonRoot[] }) {}
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertiesIds?: number[] }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: IAddonRoot[] }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(public payload: { request: IAddonRequestRoot }) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { item: IAddonRequestRoot }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { propertiesIds?: number[]; addonId: number }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { addonId: number }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(public payload: { request: IAddonRequestRoot }) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { addon: IAddonRequestRoot }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | ResetSuccessAction
  | LoadAccommodationAddonsRequestAction
  | LoadAccommodationAddonsSuccessAction
  | LoadAccommodationAddonsFailureAction;
