import {
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  NgFirstOrDefaultPipeModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { QuestionModule } from '@app/components/question/question.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzSelectModule } from 'ng-zorro-antd/select';
import {
  CreditNoteVirtualStamp,
  InvoiceDetails,
  PaymentMethod,
  RefundPaymentsCreditNote,
  TypedSimpleChanges,
} from '@app/models';
import { CreditNoteRegisterSliderComponent } from '../credit-note-register-slider/credit-note-register-slider.component';
import { CreditNoteRegisterBillsTableComponent } from '../credit-note-register-bills-table/credit-note-register-bills-table.component';
import { PageHeaderModule } from '@app/components/page-header';
import { RouterModule } from '@angular/router';
import { CreditNotePaymentsTableComponent } from '../credit-note-payments-table/credit-note-payments-table.component';
import { CreditNoteVirtualStampRegisterComponent } from '../credit-note-virtual-stamp-register/credit-note-virtual-stamp-register.component';
import { Subscription } from 'rxjs';
import { sum, sumBy, upperFirst } from 'lodash';
import { vi } from 'date-fns/locale';

@Component({
  selector: 'by-credit-note-register-form',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    NzGridModule,
    ReactiveFormsModule,
    FormsModule,
    NzFormModule,
    NzRadioModule,
    QuestionModule,
    NzToolTipModule,
    NzAlertModule,
    NzSelectModule,
    NgFirstOrDefaultPipeModule,
    CreditNoteRegisterSliderComponent,
    CreditNoteRegisterBillsTableComponent,
    PageHeaderModule,
    RouterModule,
    CreditNotePaymentsTableComponent,
    CreditNoteVirtualStampRegisterComponent,
  ],
  templateUrl: './credit-note-register-form.component.html',
  styleUrl: './credit-note-register-form.component.scss',
})
export class CreditNoteRegisterFormComponent
  implements OnChanges, OnDestroy, OnInit
{
  @ViewChild(CreditNoteRegisterBillsTableComponent)
  singleBillsTable: CreditNoteRegisterBillsTableComponent;

  @ViewChild(CreditNotePaymentsTableComponent)
  refundedPaymentsTable: CreditNotePaymentsTableComponent;

  @Input({ required: true }) paymentMethods: PaymentMethod[];

  @Input({ required: true }) invoice: InvoiceDetails;

  @Input({ required: true }) mediasInvoiceLayouts: string;

  @Input({ required: true }) isOnlyCityTaxRefund: boolean;

  @Input({ required: true }) step: 'total' | 'bills';

  private fb = inject(FormBuilder);

  private translate = inject(TranslateService);

  private subs = new Subscription();

  form = this.fb.group({
    creditNoteType: this.fb.control<'total' | 'partial'>(null),
    reversalType: this.fb.control<'import' | 'bills'>('import'),
    refundSameTime: this.fb.control<number>(0),
    totalValue: this.fb.control<number>(0),
    chargeAmountAligned: this.fb.control<number>(0),
    refundPayments: this.fb.control<RefundPaymentsCreditNote[]>([]),
    virtualStamp: this.fb.control<CreditNoteVirtualStamp>(null),
  });

  ngOnInit(): void {
    this.subs.add(
      this.form.controls.creditNoteType.valueChanges.subscribe((type) => {
        if (type === 'total') {
          this.form.patchValue(
            { totalValue: +this.invoice.maxRefundable },
            { emitEvent: false },
          );
        }

        if (this.isAdvanceInvoiceAttached) {
          this.form.patchValue({ refundSameTime: 1 }, { emitEvent: false });
        }
      }),
    );

    this.subs.add(
      this.form.controls.reversalType.valueChanges.subscribe((type) => {
        if (type === 'import' && this.isAdvanceInvoiceAttached) {
          this.form.patchValue({ refundSameTime: 1 }, { emitEvent: false });
        }
      }),
    );

    this.subs.add(
      this.form.controls.totalValue.valueChanges.subscribe((value) => {
        this.refillRefundsByDefault(value - this.virtualStampCustomerCharged);
      }),
    );

    this.subs.add(
      this.form.controls.virtualStamp.valueChanges.subscribe((virtualStamp) => {
        const { chargeToCustomer, stampAmount } = virtualStamp;

        const stampAmountToRemove = chargeToCustomer ? stampAmount : 0;

        if (this.isReversalTypeImport) {
          this.refillRefundsByDefault(this.totalToCancel - stampAmountToRemove);
          return;
        }

        this.refillRefundsByDefault(
          this.totaToCancelSplitTable - stampAmountToRemove,
        );
      }),
    );
  }

  ngOnChanges(
    changes: TypedSimpleChanges<{
      invoice: InvoiceDetails;
      isOnlyCityTaxRefund: boolean;
      paymentMethods: PaymentMethod[];
      step: 'total' | 'bills';
    }>,
  ): void {
    const { invoice, isOnlyCityTaxRefund, paymentMethods, step } = changes;

    if (step) {
      if (this.isAdvanceInvoiceAttached && this.step === 'bills') {
        this.form.patchValue({ refundSameTime: 1 }, { emitEvent: false });
      }
    }

    if (invoice && this.invoice) {
      this.form.patchValue({ totalValue: +this.invoice.maxRefundable });

      if (this.hasInvoiceLinkedDocuments || this.hasBalanceInvoices) {
        this.form.patchValue(
          { creditNoteType: 'partial' },
          { emitEvent: false },
        );

        if (this.isAdvanceInvoiceAttached) {
          this.form.patchValue({ refundSameTime: 1 }, { emitEvent: false });
        }
      }
    }

    if (paymentMethods || invoice) {
      this.generateDefaultPayments();
    }

    if (isOnlyCityTaxRefund && this.isOnlyCityTaxRefund) {
      this.form.patchValue({ reversalType: 'bills' }, { emitEvent: false });
    }
  }

  refillRefundsByDefault(value: number) {
    let balanceToRefill = value;

    const updatedRefunds = this.form.controls.refundPayments.value.map(
      (group) => {
        const { maxAmount, invoicePayment, currentAmount, ...rest } = group;

        let amountToSet = 0;

        if (invoicePayment && balanceToRefill > 0) {
          amountToSet = Math.min(maxAmount, balanceToRefill);
          balanceToRefill -= amountToSet;
        }

        return {
          ...rest,
          maxAmount,
          invoicePayment,
          currentAmount: amountToSet,
        };
      },
    );

    this.form.controls.refundPayments.patchValue(updatedRefunds);
  }

  generateDefaultPayments() {
    const { advances } = this.invoice;

    const currentMaxAmount = this.amountPayments;

    let amountAlreadySet = 0;

    const refunds = [
      ...(this.isAdvanceInvoiceAttached
        ? advances.list.map(
            ({
              advance_date,
              advance_id,
              advance_number,
              advance_refundable_amount,
              destination,
            }) => {
              const currentAmountToSet = Math.min(
                advance_refundable_amount,
                currentMaxAmount - amountAlreadySet,
              );

              amountAlreadySet += currentAmountToSet;

              return {
                maxAmount: advance_refundable_amount,
                currentAmount: currentAmountToSet,
                label: `${upperFirst(this.translate.instant('advance_invoice_number', { advanceNumber: advance_number }))} `,
                date: advance_date,
                advanceInvoiceId: advance_id,
                invoicePaymentId: null,
                invoicePayment: true,
                paymentMethodId: null,
                isSuspendPayment: false,
                moreInfo: {
                  date: advance_date,
                  customer: destination,
                },
              };
            },
          )
        : []),

      ...this.invoicePayments
        .filter(({ max_refundable }) => +max_refundable)
        .map(({ id, payment_method, max_refundable, date, label }) => {
          const currentAmountToSet = Math.min(
            +max_refundable,
            currentMaxAmount - amountAlreadySet,
          );

          amountAlreadySet += currentAmountToSet;

          return {
            maxAmount: +max_refundable,
            currentAmount: currentAmountToSet,
            label: `${upperFirst(this.translate.instant('payment'))} ${payment_method} `,
            invoicePaymentId: id,
            invoicePayment: true,
            paymentMethodId: null,
            isSuspendPayment: false,
            advanceInvoiceId: null,
            moreInfo: {
              date,
              customer: label,
            },
          };
        }),

      ...this.paymentMethods.map(({ id, name }) => ({
        maxAmount: null,
        currentAmount: 0,
        label: name,
        invoicePayment: false,
        invoicePaymentId: null,
        paymentMethodId: id,
        isSuspendPayment: false,
        advanceInvoiceId: null,
      })),

      {
        maxAmount: null,
        invoicePayment: false,
        currentAmount: 0,
        label: upperFirst(this.translate.instant('suspended')),
        invoicePaymentId: null,
        paymentMethodId: null,
        isSuspendPayment: true,
        advanceInvoiceId: null,
      },
    ];

    this.form.controls.refundPayments.setValue(refunds);
  }

  resetPayments() {
    this.generateDefaultPayments();

    this.form.patchValue({ refundSameTime: 0 }, { emitEvent: false });
  }

  refillRefundsWithVirtualStamp(singleBillsAmount: number) {
    this.refillRefundsByDefault(
      singleBillsAmount - this.virtualStampCustomerCharged,
    );
  }

  get showRefundSameTimeQuestion() {
    return this.form.value.refundSameTime && !this.isInvoiceTotalSuspended;
  }

  get creditNoteTypeValue() {
    return this.form.value.creditNoteType;
  }

  get isCreditNotePartial() {
    return this.form.value.creditNoteType === 'partial';
  }

  get isReversalTypeImport() {
    return this.form.value.reversalType === 'import';
  }

  get isReversalTypeBills() {
    return this.form.value.reversalType === 'bills';
  }

  get isTotalStep() {
    return this.step === 'total';
  }

  get formValues() {
    return this.form.getRawValue();
  }

  get tableRows() {
    return this.singleBillsTable.rowsBillValues;
  }

  get hasInvoiceLinkedDocuments() {
    return !!this.invoice.linked_documents.length;
  }

  get invoicePayments() {
    return this.invoice.payments;
  }

  get invoiceBills() {
    return this.invoice.bills_list;
  }

  get invoiceBillsLength() {
    return this.invoiceBills.length;
  }

  get isInvoiceFromReservation() {
    return this.invoice.reservation_id;
  }

  get totalToCancel() {
    return this.form.value.totalValue;
  }

  get totaToCancelSplitTable() {
    return this.singleBillsTable?.rowsBillValues.total || 0;
  }

  get creditNotePartialEmitted() {
    return this.invoice.linked_documents;
  }

  get showSplitPayments() {
    return (
      this.showRefundSameTimeQuestion &&
      (this.isCreditNotePartial || this.virtualStampCustomerCharged)
    );
  }

  get isInvoiceTotalSuspended() {
    return this.invoice.paid === 0;
  }

  get isRefunbledPaymetsNotFilled() {
    return this.refundedPaymentsTable?.isBalanceToCancelNotTotalSelected;
  }

  get virtualStampCustomerCharged() {
    const { virtualStamp } = this.form.value;

    if (virtualStamp?.chargeToCustomer) {
      return virtualStamp.stampAmount;
    }

    return 0;
  }

  get amountPayments() {
    return this.isReversalTypeBills
      ? this.totaToCancelSplitTable - this.virtualStampCustomerCharged
      : this.totalToCancel - this.virtualStampCustomerCharged;
  }

  get isAdvanceInvoice() {
    return !!(
      this.invoice.advance && this.invoice.document_type_code === 'TD02'
    );
  }

  get hasBalanceInvoices() {
    return !!this.invoice.balanceInvoices?.length;
  }

  get minAdvanceInvoiceAmountToSet() {
    if (!this.creditNotePartialEmitted && !this.isAdvanceInvoiceAttached) {
      return 0;
    }

    return Math.max(0, this.amountPayments - +this.invoice.total);
  }

  get isAdvanceInvoiceAttached() {
    return !!this.invoice?.advances?.list?.length;
  }

  get isOnlyOneAdvanceAttached() {
    return this.invoice?.advances?.list?.length === 1;
  }

  get advanceTotalSet() {
    if (!this.isAdvanceInvoiceAttached) {
      return 0;
    }

    return sumBy(
      this.form.value.refundPayments.filter(
        ({ advanceInvoiceId }) => advanceInvoiceId,
      ),
      'currentAmount',
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
