<by-spin [nzSpinning]="loading$ | async">
  <div [formGroup]="form" nz-form nzLayout="vertical">
    <nz-form-item *ngIf="propertiesIds?.length > 1">
      <nz-form-label>
        {{ 'property' | translate | capitalize }}
      </nz-form-label>
      <nz-form-control>
        <nz-select
          class="by-w-100"
          formControlName="property_id"
          [nzPlaceHolder]="'property' | translate"
        >
          <nz-option
            *ngFor="let propertyId of propertiesIds"
            [nzLabel]="propertiesNames$ | async | get: propertyId"
            [nzValue]="propertyId"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired>
        {{ 'invoice_date' | translate | capitalize }}
      </nz-form-label>
      <nz-form-control>
        <nz-date-picker
          byDisabledMobileKeyboard
          byDisabledDateFixer
          formControlName="invoice_date"
          [nzAllowClear]="false"
          nzPlaceHolder="{{
            'placeholder.select' | translate: { param: 'date' | translate }
          }}"
          nzFormat="dd/MM/yyyy"
          class="by-w-100"
          [nzPopupStyle]="{ width: '100%' }"
          [nzDisabledDate]="disabledStartDate"
        >
        </nz-date-picker> </nz-form-control
    ></nz-form-item>

    <by-question
      *ngIf="invoiceHasAlredyANumber"
      [label]="
        'invoice_has_already_a_number_want_to_change_it'
          | translate: { number: invoice?.number_complete }
          | upperfirst
      "
      [formControl]="userWantsToChangeTheNumber"
      [yesValue]="true"
      [noValue]="false"
    ></by-question>

    <ng-container
      *ngIf="!invoiceHasAlredyANumber || userWantsToChangeTheNumber.value"
    >
      <nz-form-item *ngIf="layout?.sectionals?.length">
        <nz-form-label nzRequired>
          {{ 'sectional' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-select
            class="by-w-100"
            formControlName="invoice_layout_sectional_id"
            [nzPlaceHolder]="'sectional' | translate"
          >
            <nz-option
              [nzLabel]="'no_sectional' | translate"
              [nzValue]="0"
            ></nz-option>
            <ng-container *ngFor="let sectional of layout?.sectionals || []">
              <nz-option
                [nzLabel]="sectional.prefix"
                [nzValue]="sectional.id"
              ></nz-option>
            </ng-container>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>
          {{ 'invoice_number' | translate | upperfirst }}
        </nz-form-label>
        <nz-form-control>
          <nz-input-number
            class="by-w-100"
            byNumberOnly
            [nzMin]="1"
            formControlName="invoice_number"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
      @if (isCreditNote) {
        <nz-form-item>
          <nz-form-label>
            {{ 'causal' | translate | upperfirst }}
            <i
              class="fas fa-info-circle color--orange by-ml-5"
              nz-tooltip
              [nzTooltipTitle]="templateCausal"
              [nzTooltipTitleContext]="{ label: 'invoice_causal_description' }"
            ></i>

            <ng-template #templateCausal let-label="label">
              <p>{{ label | translate | upperfirst }}</p>
              <p>
                {{ 'characters_not_allowed' | translate | upperfirst }}:
                {{ sdiCharactersNotAllowed }}
              </p>
            </ng-template>
          </nz-form-label>
          <nz-form-control>
            <textarea nz-input formControlName="causal"></textarea>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label>
            {{ 'extra_notes' | translate | upperfirst }}
          </nz-form-label>
          <nz-form-control>
            <textarea nz-input formControlName="extra_notes"></textarea>
          </nz-form-control>
        </nz-form-item>
      }

      <nz-alert
        *ngIf="numberAlredyUsed$ | async"
        nzType="warning"
        [nzMessage]="
          'this_invoice_number_alredy_used'
            | translate: { number: invoiceNumber }
            | upperfirst
        "
        nzShowIcon
      ></nz-alert>

      <nz-alert
        *ngIf="selectDateNumberNoSequential$ | async"
        nzType="warning"
        [nzMessage]="'date_number_no_sequential' | translate | upperfirst"
        nzShowIcon
      ></nz-alert>
    </ng-container>
  </div>
</by-spin>
