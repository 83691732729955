import moment from 'moment';

import { IAddonRoot } from '../models';
import { EstimateAddonModalData } from '../models/objects/estimate-addon-modal';

interface Params {
  accommodation_id: number;
  property_id: number;
  adults_number: number;
  total_children: Array<{ id: number; quantity: number }>;
  date_from: string;
  date_to: string;
}

export const getEstimateAddons = (
  addons: IAddonRoot[],
  params: Params,
): EstimateAddonModalData[] => {
  return (addons || []).map(
    ({
      id,
      price_type,
      vat_quote_id,
      addon_category_id,
      category_name,
      price,
      name,
    }): EstimateAddonModalData => {
      const {
        property_id,
        accommodation_id,
        date_from,
        date_to,
        adults_number,
        total_children,
      } = params;

      const date_ranges = [
        {
          from: date_from,
          to: date_to,
        },
      ];

      return {
        addon_id: id,
        name,
        type: price_type,
        quantity:
          price_type !== 'xnight'
            ? 1
            : moment(new Date(date_to)).diff(new Date(date_from), 'days'),
        price: +price,
        discount_type_id: 5,
        vat_quote_id: vat_quote_id || 1,
        category_title: category_name || '',
        category_id: addon_category_id || -1,
        date_ranges,
        total_children,
        adults_number,
        accommodation_id,
        property_id,
      };
    },
  );
};
