import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkHandlerService } from '@app/services/network-handler.service';
import { TokenService } from '@core/services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { emitMobileAppEvent } from '@app/helpers';

@Injectable({
  providedIn: 'root',
})
export class CoreHttpInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private translate: TranslateService,
    private network: NetworkHandlerService,
    private router: Router,
  ) {}

  get online() {
    return navigator.onLine;
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.match('/assets/i18n/')) {
      return next.handle(req);
    }

    if (req.url.match('http')) {
      return next.handle(req);
    }

    const isLocalUrl = localStorage.getItem('use_local_url');

    const lang = req.urlWithParams.includes('lang=')
      ? null
      : this.translate.currentLang;

    const apiUrl = isLocalUrl || environment.apiUrl;

    const shouldAddDomain = !req.url.match(apiUrl);
    const url = shouldAddDomain ? apiUrl + req.url : req.url;

    const hasParams = url.match(/\?/);

    let request = req.clone({
      url: `${url}${hasParams ? (lang ? '&' : '') : '?'}${
        lang ? 'lang=' + lang : ''
      }`,
    });

    if (!this.tokenService.get()) {
      return this.handleRequest(next, request);
    }

    return this.tokenService.getOrRefreshToken().pipe(
      switchMap((token) => {
        if (!token) {
          return throwError('token_expired');
        }

        let headers = request.headers;

        if (!req.headers.get('Authorization')) {
          headers = req.headers.set('Authorization', `Bearer ${token}`);
        }

        headers = headers.set('Bdy-Client-Path', window.location.pathname);

        request = request.clone({ headers });

        return this.handleRequest(next, request);
      }),
    );
  }

  private handleRequest(next: HttpHandler, request: HttpRequest<any>) {
    return next.handle(request).pipe(
      catchError((error) => {
        emitMobileAppEvent(
          'logErrors',
          JSON.stringify({ error: JSON.stringify(error) }),
        );
        if (error instanceof HttpErrorResponse && error.status === 503) {
          this.router.navigate(['/503']);
        }

        return throwError(() => error);
      }),
      retry({
        delay: (errors) => {
          if (this.online) {
            return throwError(() => errors);
          }

          return this.network.canUseHttp$;
        },
      }),
    );
  }
}
