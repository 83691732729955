import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { getBase64 } from '../../core/helpers/get-base-64';
import { Attachment } from '../../models';

import { AttachmentViewerComponent } from './attachment-viewer.component';
import { AttachmentModalData } from '../../models/objects/attachment-viewer-modal-data';

@Injectable({ providedIn: 'root' })
export class AttachmentViewerService {
  modal: NzModalRef<AttachmentViewerComponent>;

  printData: {
    src: string;
    fileName: string;
  };

  constructor(
    private modalService: NzModalService,
    private translate: TranslateService,
  ) {}

  view(data: Partial<Attachment> | File) {
    if (data instanceof File) {
      this.open({
        src: URL.createObjectURL(data),
        fileName: data.name,
      });

      return;
    }
    this.open({
      fileName: data?.label,
      src: data?.media || data?.path,
    });
  }

  close() {
    this.modal?.close();
  }

  open(data: { src?: string; fileName?: string; loading?: boolean }) {
    if (!this.modal) {
      this.createAttachmentViewerModal(data);
    } else {
      this.setAttachmentViewerInput(data);
    }
  }

  private setAttachmentViewerInput(data: {
    src?: string;
    fileName?: string;
    loading?: boolean;
  }) {
    this.modal.componentInstance._src = data.src;

    this.modal.componentInstance.title =
      data?.fileName || this.translate.instant('document');

    this.modal.componentInstance.loading = false;

    this.modal.componentInstance.printData = this.printData;
  }

  private createAttachmentViewerModal(data: {
    src?: string;
    fileName?: string;
    loading?: boolean;
  }): void {
    this.modal = this.modalService.create<
      AttachmentViewerComponent,
      Pick<
        AttachmentModalData,
        '_src' | 'title' | 'loading' | 'height' | 'printData'
      >
    >({
      nzTitle: null,
      nzContent: AttachmentViewerComponent,
      nzClosable: false,
      nzData: {
        _src: data.src,
        title: data?.fileName || this.translate.instant('document'),
        loading: data.loading,
        height: this.height,
        printData: this.printData,
      },
      nzWidth: '95%',
      nzStyle: { top: '20px' },
      nzBodyStyle: { padding: '0', height: this.height },
      nzFooter: null,
    });

    this.modal.afterClose.subscribe(() => {
      this.modal = null;

      this.printData = null;
    });
  }

  get getComponentInstance(): AttachmentViewerComponent {
    return this.modal?.componentInstance;
  }

  get height() {
    /**
     * @description
     * height of <by-attachment-viewer-tool-bar> component
     */
    const toolBarHeight = 44;

    return `${window.innerHeight - toolBarHeight}px`;
  }
}
